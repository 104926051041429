import { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useSelector, useDispatch } from 'react-redux'
import { getFarm } from 'utils/getFarmConfig'
import { QuoteToken } from 'config/constants/types'
import useRefresh from 'hooks/useRefresh'
import {
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchVaultsPublicDataAsync,
  fetchSmoyPrice,
  fetchQuote,
  fetchUserGodfathers,
  fetchUserPuppys,
  fetchMiniGamePublicInfo,
  fetchLotteryPublicInfo,
  fetchMarketplacePublicInfo,
  fetchMintPuppyGamePublicInfo,
} from './actions'
import {
  State,
  Farm,
  Pool,
  Vault,
  NFTInfo,
  MiniGameState,
  LotteryState,
  MarketplaceState,
  MintPuppyGame,
  RaffleState,
} from './types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
    dispatch(fetchVaultsPublicDataAsync())
    dispatch(fetchSmoyPrice())
    dispatch(fetchQuote())
  }, [dispatch, slowRefresh])
}

export const useFetchGamePublicData = () => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMiniGamePublicInfo())
  }, [dispatch, fastRefresh])
}

export const useFetchLotteryPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchLotteryPublicInfo())
  }, [dispatch, slowRefresh])
}

export const useFetchMarketplacePublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMarketplacePublicInfo())
  }, [dispatch, slowRefresh])
}

export const useFetchMintPuppyGamePublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMintPuppyGamePublicInfo())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarmUnlockDate = (): Date => {
  const unlockDate = useSelector((state: State) => state.farms.farmUnlockAt)
  return unlockDate
}

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Vaults
export const useVaults = (): Vault[] => {
  const vaults = useSelector((state: State) => state.vaults.data)
  return vaults
}

export const useVaultRaffle = (): RaffleState => {
  const raffle = useSelector((state: State) => state.vaults.raffle)
  return raffle
}

export const useVaultFromPid = (pid): Vault => {
  const vault = useSelector((state: State) => state.vaults.data.find((f) => f.pid === pid))
  return vault
}

export const useVaultFromSymbol = (lpSymbol: string): Vault => {
  const vault = useSelector((state: State) => state.vaults.data.find((f) => f.lpSymbol === lpSymbol))
  return vault
}

export const useVaultUser = (pid) => {
  const vault = useVaultFromPid(pid)

  return {
    lpAllowance: vault.userData ? new BigNumber(vault.userData.lpAllowance) : new BigNumber(0),
    lpBalance: vault.userData ? new BigNumber(vault.userData.lpBalance) : new BigNumber(0),
    lpStakedBalance: vault.userData ? new BigNumber(vault.userData.lpStakedBalance) : new BigNumber(0),

    busdAllowance: vault.userData ? new BigNumber(vault.userData.busdAllowance) : new BigNumber(0),
    busdBalance: vault.userData ? new BigNumber(vault.userData.busdBalance) : new BigNumber(0),
  }
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const { pid } = getFarm(QuoteToken.BNBBUSD) // BUSD-SMOY LP
  const farm = useFarmFromPid(pid)

  const pancakeBnbPrice = useSelector((state: State) => state.smoyPrice.pancakeBnbPrice)
  if (pancakeBnbPrice) return new BigNumber(pancakeBnbPrice)

  if (!farm) return ZERO

  return farm.tokenPriceVsQuote && farm.tokenPriceVsQuote.toString() !== 'NaN'
    ? new BigNumber(1).div(farm.tokenPriceVsQuote)
    : ZERO
}

// export const usePriceSmoyBusd = (): BigNumber => {
//   const { pid } = getFarm(QuoteToken.SMOYBUSD) // BUSD-SMOY LP
//   const farm = useFarmFromPid(pid)
//   if (!farm) return ZERO
//   return farm.tokenPriceVsQuote ? new BigNumber(1).div(farm.tokenPriceVsQuote) : ZERO
// }

export const usePriceSmoyUsd = (): BigNumber => {
  const smoyPrice = useSelector((state: State) => state.smoyPrice.smoyPrice)
  return new BigNumber(smoyPrice)
}

export const usePriceTVL = (): BigNumber => {
  const { account } = useWallet()
  const pools = usePools(account)
  const smoyUsd = usePriceSmoyUsd()
  const selectedPools = pools.find((pool) => pool.sousId === 0)

  const pancakeBnbPrice = useSelector((state: State) => state.smoyPrice.pancakeBnbPrice)
  const smoyBusdQuote = useSelector((state: State) => state.smoyPrice.smoyBusdQuote)
  const smoyWbnbQuote = useSelector((state: State) => state.smoyPrice.smoyWbnbQuote)
  const wbnbBusdQuote = useSelector((state: State) => state.smoyPrice.wbnbBusdQuote)
  const usdtBusdQuote = useSelector((state: State) => state.smoyPrice.usdtBusdQuote)
  const kMaticWbnbQuote = useSelector((state: State) => state.smoyPrice.kMaticWbnbQuote)

  let totalStaked = new BigNumber(0)
  switch (typeof selectedPools.totalStaked) {
    case 'undefined':
      totalStaked = new BigNumber(0)
      break
    case 'string':
      totalStaked = new BigNumber((parseFloat(selectedPools.totalStaked) || 0) / 10 ** selectedPools.tokenDecimals)
      break
    default:
      totalStaked = selectedPools.totalStaked.times(new BigNumber(10).pow(18))
      break
  }

  const totalSmoy = totalStaked.times(smoyUsd).toNumber()
  const smoyBusdPrice = new BigNumber(smoyBusdQuote).times(smoyUsd)
  const smoyWbnbPrice = new BigNumber(smoyWbnbQuote).times(smoyUsd)
  const wbnbBusdPrice = new BigNumber(wbnbBusdQuote).times(pancakeBnbPrice)
  const usdtBusdPrice = new BigNumber(usdtBusdQuote)
  const kMaticWbnbPrice = new BigNumber(kMaticWbnbQuote).times(pancakeBnbPrice)

  return BigNumber.sum.apply(null, [
    smoyBusdPrice,
    smoyWbnbPrice,
    wbnbBusdPrice,
    usdtBusdPrice,
    kMaticWbnbPrice,
    new BigNumber(totalSmoy),
  ])
}

export const useVaultTVL = (): BigNumber => {
  const vaults = useSelector((state: State) => state.vaults)
  const bnbPrice = usePriceBnbBusd()
  const smoyPrice = usePriceSmoyUsd()

  if (!vaults.data || !Array.isArray(vaults.data)) return new BigNumber(0)
  let totalVaultTVL = new BigNumber(0)

  const tvlList = vaults.data.map((vault) => {
    let tvl = new BigNumber(0)
    if ([QuoteToken.BUSD, QuoteToken.USDT].includes(vault.quoteTokenSymbol)) {
      tvl = new BigNumber(vault.quoteTokenBalanceOfStrategy)
      totalVaultTVL = totalVaultTVL.plus(tvl)
    } else if (vault.quoteTokenSymbol === QuoteToken.BNB) {
      tvl = new BigNumber(vault.quoteTokenBalanceOfStrategy).times(bnbPrice)
      totalVaultTVL = totalVaultTVL.plus(tvl)
    } else if (vault.quoteTokenSymbol === QuoteToken.SMOY) {
      tvl = new BigNumber(vault.quoteTokenBalanceOfStrategy).times(smoyPrice)
      totalVaultTVL = totalVaultTVL.plus(tvl)
    }
    return tvl
  })

  return BigNumber.sum.apply(null, [...tvlList])
}

export const usePriceEthBusd = (): BigNumber => {
  // const pid = 6 // ETH-BNB LP
  const pid = 10 // ETH-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  const farm = useFarmFromPid(pid)
  if (!farm) return ZERO
  return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceEthBnb = (): BigNumber => {
  const priceBnbBusd = usePriceBnbBusd()
  const priceEthBusd = usePriceEthBusd()
  return priceEthBusd.div(priceBnbBusd)
}

export const useGodfathers = (account): [NFTInfo[], boolean] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchUserGodfathers(account))
    }
  }, [account, dispatch, fastRefresh])

  const godfathers = useSelector((state: State) => state.godfathers.data)
  const loading = useSelector((state: State) => state.godfathers.loading)
  return [godfathers, loading]
}

export const usePuppys = (account): [NFTInfo[], boolean] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchUserPuppys(account))
    }
  }, [account, dispatch, fastRefresh])

  const puppys = useSelector((state: State) => state.puppys.data)
  const loading = useSelector((state: State) => state.puppys.loading)
  return [puppys, loading]
}

export const useMiniGameState = (): MiniGameState => {
  const miniGame = useSelector((state: State) => state.miniGames)
  return miniGame
}

export const useLotteryState = (): LotteryState => {
  const lottery = useSelector((state: State) => state.lotteries)
  return lottery
}

export const useMintPuppyGameState = (): MintPuppyGame => {
  const mintPuppyGame = useSelector((state: State) => state.mintPuppyGame)
  return mintPuppyGame
}

export const useMarketplaceState = (): MarketplaceState => {
  const marketplace = useSelector((state: State) => state.marketplace)
  return marketplace
}
