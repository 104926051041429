import React, { useState } from 'react'
import { usePriceSmoyUsd } from 'state/hooks'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Button, Link } from '@mui/material'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import numeral from 'numeral'
import UnlockButton from 'components/UnlockButton'
import PawButtton from 'components/Button/PawButtton'
import TextShadow from 'components/Text/TextShadow'
import { getSmoyAddress } from 'utils/addressHelpers'
import UserModal from '../WalletModal/UserModal'

const useStyles = makeStyles((theme) => ({
  priceBar: {
    display: 'none',
    '& > img': {
      width: 36,
      marginRight: theme.spacing(0.5),
    },
    '& > a': {
      fontSize: '18px',
      color: '#333333',
      fontFamily: "'Fredoka One'",
    },
  },
  priceText: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  buttonCover: {
    '& .MuiButton-root': {
      fontSize: '13px',
      lineHeight: '13px',
      padding: '12px 4px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    priceBar: {},
    buttonCover: {
      '& .MuiButton-root': {
        fontSize: '20px',
        lineHeight: '20px',
        padding: theme.spacing(1.5, 1),
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    priceBar: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      backgroundColor: 'rgba(43, 28, 47, 0.33)',
      padding: '8px',
      borderRadius: '4px',
      '& > img': {
        marginRight: theme.spacing(1),
      },
      '& .MuiButton-root': {
        fontSize: '16px',
        lineHeight: '16px',
        padding: theme.spacing(0.75),
        marginLeft: theme.spacing(1),
      },
    },
    buttonCover: {
      '& .MuiButton-root': {
        fontSize: '16px',
        lineHeight: '16px',
        padding: theme.spacing(1.5, 1),
      },
    },
  },
  [theme.breakpoints.up('xl')]: {
    buttonCover: {
      '& .MuiButton-root': {
        fontSize: '20px',
        lineHeight: '20px',
        padding: theme.spacing(1.5, 2),
      },
    },
  },
}))

const UserPanel = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { account } = useWallet()
  const accountEllipsis = account ? `${account.substring(0, 5)}...${account.substring(account.length - 3)}` : null
  const priceSmoyUsd = usePriceSmoyUsd()
  const smoyAddress = getSmoyAddress()

  const handleOpenMenu = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box className={classes.priceBar}>
        <img src="/images/logo.svg" alt="smoy" />

        <TextShadow color="white" size="18" colorBorder="black" sizeBorder="2" lineHeight="24">
          <Link
            href={`https://swap.arken.finance/tokens/bsc/${smoyAddress}`}
            target="_blank"
            className={classes.priceText}
          >
            {priceSmoyUsd ? `$${numeral(priceSmoyUsd).format('0,0.00[00]')}` : ''}
          </Link>
        </TextShadow>

        <PawButtton href="/swap" color="yellow">
          BUY
        </PawButtton>
      </Box>
      <Box className={classes.buttonCover}>
        {account ? (
          <>
            <Button variant="contained" color="secondary" onClick={handleOpenMenu}>
              {accountEllipsis}
            </Button>
            <UserModal open={open} onClose={handleClose} />
          </>
        ) : (
          <UnlockButton usePawButton variant="contained" color="secondary" />
        )}
      </Box>
    </>
  )
}

export default UserPanel
