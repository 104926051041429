export default {
  raffle: {
    97: '0x142109203b3653Cfcc607d5B584711C88DDef57c',
    56: '0xcF586d88EcA628d2F62791352Fb36DBDA3B0514e',
  },
  smoyVaultHelper: {
    97: '0xEAef554111Ad167af6C9B4fEA91DB228b74AE198',
    56: '',
  },
  // babyVaultHelper: {
  //   97: '',
  //   56: '0xc367d1d2E16fB597684BCd24Dc81a66332631FdB',
  // },
  bswVaultHelper: {
    97: '',
    56: '0x85ACfa6EFe7d536c19A30fD154F1f8BA02bAc5fB',
  },
  kswVaultHelper: {
    97: '',
    56: '0x89d7dDcaf8C7d8F4506925b242cfF5b6452f8b55',
  },

  // MasterChef =====================================================
  // babyMasterChef: {
  //   97: '',
  //   56: '0xdfAa0e08e357dB0153927C7EaBB492d1F60aC730',
  // },
  bswMasterChef: {
    97: '',
    56: '0xdbc1a13490deef9c3c12b44fe77b503c1b061739',
  },
  kswMasterChef: {
    97: '',
    56: '0x0d0A09bc1D1a9fec8901Ddf03aBCA488eC78F9A3',
  },

  // Token =====================================================
  // babyToken: {
  //   97: '',
  //   56: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
  // },
  bswToken: {
    97: '',
    56: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
  },
  avaxToken: {
    97: '',
    56: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
  },
  kswToken: {
    97: '',
    56: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
  },
  galaToken: {
    97: '',
    56: '0x7dDEE176F665cD201F93eEDE625770E2fD911990',
  },
  maticToken: {
    97: '',
    56: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
  },

  // LP =====================================================
  BSWxBnbBusdLP: {
    97: '',
    56: '0xacaac9311b0096e04dfe96b6d87dec867d3883dc',
  },
  BSWxAvaxBnbLP: {
    97: '',
    56: '0x2f3899fFB9FdCf635132F7bb94c1a3A0F906cc6f',
  },
  BSWxGalaBnbLP: {
    97: '',
    56: '0x4F00ADEED60FCba76e58a5d067b6A4b9Daf8e30f',
  },
  BSWxMaticBnbLP: {
    97: '',
    56: '0x3B09e13Ca9189FBD6a196cfE5FbD477C885afBf3',
  },
  BSWxUsdtBusdLP: {
    97: '',
    56: '0xDA8ceb724A06819c0A5cDb4304ea0cB27F8304cF',
  },
  BSWxBswBusdLP: {
    97: '',
    56: '0xB22Bc4DB1DCd0B45680b4D831F8C06CFf50Ee4D6',
  },
  KSWxKswBnbLP: {
    97: '',
    56: '0x2be28Dbf88DaFD390b4A07f5DD1FC80244AA84C6',
  },
  KSWxSmoyIzlude: {
    97: '',
    56: '0x5E09E6df7c5486e504af49D1793D254C2D1827De',
  },

  // BABYxGalaUsdtLP: {
  //   97: '',
  //   56: '0xb91c780792EB5168263A21b583FDCdE50446Ff1C',
  // },
  // BABYxMaticUsdtLP: {
  //   97: '',
  //   56: '0xAB8C7Ef18a51fb865FCEEb8773Fd801fBF89DDA7',
  // },
  // BABYxBabyBnbLP: {
  //   97: '',
  //   56: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
  // },

  // Vault-LP =====================================================
  SMOYxSmoyVault: {
    97: '0x14c98F453Cd348FA711494550864e169B59aB767',
    56: '',
  },
  SMOYxSmoyBnbVault: {
    97: '0xAA9DA44D33FaAC86fE1b6c050BAc2A5660BeeB7D',
    56: '',
  },
  SMOYxSmoyBusdVault: {
    97: '0x05b6e04B975d3Cd34b5c119B7599cB1bf8306Dc1',
    56: '',
  },
  SMOYxBnbBusdVault: {
    97: '0xDdb88f55Cc0A49171D4c8ca31dfEcB4739A56a33',
    56: '',
  },

  KSWxSmoyVault: {
    97: '',
    56: '0xdB7bE0267de798d39401AbB81f98a13Aad9636c9',
  },
  BSWxBnbBusdVault: {
    97: '',
    56: '0xa99226CfaECD2160C73969d1058284CF1358884f',
  },
  BSWxAvaxBnbVault: {
    97: '',
    56: '0xD257a7F57378c4fB733e395E7B8Cd1D4330A8878',
  },
  BSWxUsdtBusdVault: {
    97: '',
    56: '0x4C13a203bD6de8D5DbE0B82E6C9EB9cfA168d8FF',
  },
  BSWxGalaBnbVault: {
    97: '',
    56: '0x0e229A28772347dD86D7b4f04d23b16126ee7343',
  },
  BSWxMaticBnbVault: {
    97: '',
    56: '0x6434ed4bE521B6CD1c79469baFA21B60276ae1F9',
  },
}
