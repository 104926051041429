import React from 'react'
import { CircularProgress, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const PageLoader: React.FC = () => {
  const classes = useStyles()
  return (
    <Paper elevation={0} className={classes.wrapper}>
      <CircularProgress color="secondary" />
    </Paper>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 245px)',
  }, 
}))

export default PageLoader
