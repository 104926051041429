export enum QuoteToken {
  'BNB' = 'BNB',
  'WBNB' = 'WBNB',
  'BUSD' = 'BUSD',
  'SMOY' = 'SMOY',
  'PUPPY' = 'PUPPY',
  'USDT' = 'USDT',
  'KMATIC' = 'kMATIC',
  'GALA' = 'GALA',
  'MATIC' = 'MATIC',
  'SMOYBNB' = 'SMOY-BNB',
  'SMOYBUSD' = 'SMOY-BUSD',
  'SMOYUSDT' = 'SMOY-USDT',
  'BNBBUSD' = 'BNB-BUSD',
  'USDTBUSD' = 'USDT-BUSD',
  'KMATICBNB' = 'kMATIC-BNB',
  'BNBUSDT' = 'BNB-USDT',
  'BABY' = 'BABY',
  'BSW' = 'BSW',
  'AVAX' = 'AVAX',
  'AVAXBNB' = 'AVAX-BNB',
  'KSW' = 'KSW',
  'KSWBNB' = 'KSW-BNB',
  'GALABNB' = 'GALA-BNB',
  'MATICBNB' = 'MATIC-BNB',
  // 'GALAUSDT' = 'GALA-USDT',
  // 'MATICUSDT' = 'MATIC-USDT',
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  97?: string
  56: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  tokenSymbol: string
  tokenAddresses: Address
  quoteTokenSymbol: QuoteToken
  quoteTokenAddresses: Address
  multiplier?: string
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface PoolConfig {
  sousId: number
  image?: string
  tokenName: string
  stakingTokenName: QuoteToken
  stakingLimit?: number
  stakingTokenAddress?: string
  contractAddress: Address
  poolCategory: PoolCategory
  projectLink: string
  tokenPerBlock?: number
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  tokenDecimals: number
}

export interface VaultConfig extends PlatformConfig {
  order: number // order number for Samoyed UI
  vaultSymbol: string
  vaultAddresses: Address // Samoyed's vault address

  pid: number // pid of LP in MasterChef
  lpSymbol: string // native LP symbol
  lpAddresses: Address // native LP symbol
  tokenSymbol: string // LP.Token0.Symbol
  tokenAddresses: Address // LP.Token0.Address
  quoteTokenSymbol: QuoteToken // LP.Token1.Symbol
  quoteTokenAddresses: Address // LP.Token1.Address

  isToken?: boolean // false = LP, true = token
}

export interface PlatformConfig {
  platform: string // target platform
  helper: Address // Vault helper for each Router

  // MasterChef variable
  masterchefABI: 'babyswap' | 'samoyed' | 'biswap' | 'killswitch' // path to MasterChef ABI
  masterchefAddresses: Address // MasterChef address
  rewardPerBlockField: string // A variable in MasterChef refer to 'RewardPerBlock'
  pendingRewardField: string // A variable in MasterChef refer to 'PendingReward'

  // Reward variable
  rewardTokenPriceLPAddresses: Address // LP address for calculate price and TVL
  tokenAddressOfRewardLP: Address // Token address of masterchef's reward
  tokenSymbolOfRewardLP: string // Token address of masterchef's reward
  quoteTokenAddressOfRewardLP: Address // Quote token for calculate reward price
  quoteTokenSymbolOfRewardLP: string // Quote token for calculate reward price

  kswIzlude?: Address // KSW Vault
  finalMasterchefABI?: 'samoyed' // KSW Vault -> final Masterchef ABI
  finalMasterchefAddresses?: Address // KSW Vault -> final Masterchef Address
  finalMasterchefpid?: number // KSW Vault -> final Masterchef pid
}
