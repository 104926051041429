/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import {
  getMasterChefAddress,
  getSmoyAddress,
  getBusdAddress,
  getWbnbAddress,
  getSmoyBusdLPAddress,
  getSmoyBnbLPAddress,
  getBnbBusdLPAddress,
  getUsdtBusdLPAddress,
  getKMaticBnbLPAddress,
  getPancakeBnbBusdLPAddress,
} from 'utils/addressHelpers'
import { createSlice } from '@reduxjs/toolkit'
import { SmoyPriceState } from '../types'

const initialState: SmoyPriceState = {
  price: 0,
  smoyPrice: 0,
  pancakeBnbPrice: 0,
  smoyBusdQuote: 0,
  smoyWbnbQuote: 0,
  wbnbBusdQuote: 0,
  usdtBusdQuote: 0,
  kMaticWbnbQuote: 0,
}

export const smoyPriceSlice = createSlice({
  name: 'SmoyPrice',
  initialState,
  reducers: {
    setSmoyPrice: (state, action) => {
      const { price } = action.payload
      state.smoyPrice = price
    },
    // setPuppyPrice: (state, action) => {
    //   const { price } = action.payload
    //   state.price = price
    // },
    setQuote: (state, action) => {
      const { pancakeBnbPrice, smoyBusdQuote, smoyWbnbQuote, wbnbBusdQuote, usdtBusdQuote, kMaticWbnbQuote } =
        action.payload
      state.pancakeBnbPrice = pancakeBnbPrice
      state.smoyBusdQuote = smoyBusdQuote
      state.smoyWbnbQuote = smoyWbnbQuote
      state.wbnbBusdQuote = wbnbBusdQuote
      state.usdtBusdQuote = usdtBusdQuote
      state.kMaticWbnbQuote = kMaticWbnbQuote
    },
  },
})

// Actions
export const { setSmoyPrice, setQuote } = smoyPriceSlice.actions

const getTotalBalanceLp = async ({ lpAddress, pair1, pair2 }) => {
  let pair1Amount = 0
  let pair2Amount = 0
  try {
    const calls = [
      {
        address: pair1,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: pair2,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: pair1,
        name: 'decimals',
      },
      {
        address: pair2,
        name: 'decimals',
      },
    ]

    const [pair1BalanceLP, pair2BalanceLP, pair1Decimals, pair2Decimals] = await multicall(erc20, calls)

    pair1Amount = new BigNumber(pair1BalanceLP).div(new BigNumber(10).pow(pair1Decimals)).toNumber()
    pair2Amount = new BigNumber(pair2BalanceLP).div(new BigNumber(10).pow(pair2Decimals)).toNumber()
  } catch (error) {
    console.error(error)
  }
  return [pair1Amount, pair2Amount]
}

const getTotalQuote = async ({ lpAddress, qouteToken }) => {
  let lpTotalInQuoteToken = 0
  try {
    const calls = [
      // Balance of quote token on LP contract
      {
        address: qouteToken,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of LP tokens in the master chef contract
      {
        address: lpAddress,
        name: 'balanceOf',
        params: [getMasterChefAddress()],
      },
      // Total supply of LP tokens
      {
        address: lpAddress,
        name: 'totalSupply',
      },
    ]

    const [quoteTokenBlanceLP, lpTokenBalanceMC, lpTotalSupply] = await multicall(erc20, calls)
    // const [quoteTokenBlanceLP] = await multicall(erc20, calls)

    const lpTokenRatio = new BigNumber(lpTotalSupply).isZero()
      ? new BigNumber(0)
      : new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

    // const lpTokenRatio = 1
    lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
      .div(new BigNumber(10).pow(18))
      .times(new BigNumber(2))
      .times(lpTokenRatio)
      .toNumber()
  } catch (error) {
    console.error('getTotalQuote', error)
  }
  return lpTotalInQuoteToken
}

// Thunks
/*
export const fetchSmoyPrice = () => async (dispatch) => {
  const response = await axios.get(
    'https://s3-ap-southeast-1.amazonaws.com/database-s3public-g8ignhbbbk6e/prices/Current.json',
  )
  const usdPrice = _.get(response, 'data.assets.smoy.usd')
  dispatch(
    setSmoyPrice({
      smoyPrice: usdPrice,
    }),
  )
}
*/

export const fetchSmoyPrice = () => async (dispatch) => {
  const fetchPromise = []

  fetchPromise.push(
    getTotalBalanceLp({
      lpAddress: getSmoyBnbLPAddress(),
      pair1: getSmoyAddress(),
      pair2: getWbnbAddress(),
      // masterChefAddress: getMasterChefAddress(),
    })
  )

  fetchPromise.push(
    getTotalBalanceLp({
      lpAddress: getSmoyBusdLPAddress(),
      pair1: getSmoyAddress(),
      pair2: getBusdAddress(),
      // masterChefAddress: getMasterChefAddress(),
    })
  )

  fetchPromise.push(
    getTotalBalanceLp({
      lpAddress: getPancakeBnbBusdLPAddress(),
      pair1: getWbnbAddress(),
      pair2: getBusdAddress(),
      // masterChefAddress: getPancakeMasterChefAddress(),
    })
  )

  const [
    [totalSmoyInSmoyBnbPair, totalBnbInSmoyBnbPair],
    [totalSmoyInSmoyBusdPair, totalBusdInSmoyBusdPair],
    [totalBnbInBnbBusdPair, totalBusdInBnbBusdPair],
  ] = await Promise.all(fetchPromise)

  const pancakeBnbPrice = totalBnbInBnbBusdPair !== 0 ? totalBusdInBnbBusdPair / totalBnbInBnbBusdPair : 0
  const totalSmoyInAllPair = totalSmoyInSmoyBusdPair + totalSmoyInSmoyBnbPair
  const totalUsdInAllPair = totalBusdInSmoyBusdPair + totalBnbInSmoyBnbPair * pancakeBnbPrice

  const averageSmoyPrice = totalSmoyInAllPair !== 0 ? totalUsdInAllPair / totalSmoyInAllPair : 0

  dispatch(
    setSmoyPrice({
      price: averageSmoyPrice,
      pancakeBnbPrice,
      totalSmoyInSmoyBusdPair,
      totalBusdInSmoyBusdPair,
      totalSmoyInSmoyBnbPair,
      totalBnbInSmoyBnbPair,
      totalBnbInBnbBusdPair,
      totalBusdInBnbBusdPair,
    })
  )
}

// Thunks
export const fetchQuote = () => async (dispatch) => {
  const smoyAddress = getSmoyAddress()
  const busdAddress = getBusdAddress()
  const wbnbAddress = getWbnbAddress()

  const fetchPromise = []
  fetchPromise.push(
    getTotalQuote({
      lpAddress: getSmoyBnbLPAddress(),
      qouteToken: smoyAddress,
    })
  )
  fetchPromise.push(
    getTotalQuote({
      lpAddress: getSmoyBusdLPAddress(),
      qouteToken: smoyAddress,
    })
  )
  fetchPromise.push(
    getTotalQuote({
      lpAddress: getBnbBusdLPAddress(),
      qouteToken: wbnbAddress,
    })
  )
  fetchPromise.push(
    getTotalQuote({
      lpAddress: getUsdtBusdLPAddress(),
      qouteToken: busdAddress,
    })
  )
  fetchPromise.push(
    getTotalQuote({
      lpAddress: getKMaticBnbLPAddress(),
      qouteToken: wbnbAddress,
    })
  )
  fetchPromise.push(
    getTotalBalanceLp({
      lpAddress: getPancakeBnbBusdLPAddress(),
      pair1: getWbnbAddress(),
      pair2: getBusdAddress(),
      // masterChefAddress: getPancakeMasterChefAddress(),
    })
  )

  const [
    smoyWbnbQuote,
    smoyBusdQuote,
    wbnbBusdQuote,
    usdtBusdQuote,
    kMaticWbnbQuote,
    [totalBnbInBnbBusdPair, totalBusdInBnbBusdPair],
  ] = await Promise.all(fetchPromise)

  const pancakeBnbPrice = totalBnbInBnbBusdPair !== 0 ? totalBusdInBnbBusdPair / totalBnbInBnbBusdPair : 0

  dispatch(
    setQuote({
      pancakeBnbPrice,
      smoyBusdQuote,
      smoyWbnbQuote,
      wbnbBusdQuote,
      usdtBusdQuote,
      kMaticWbnbQuote,
    })
  )
}
export default smoyPriceSlice.reducer
