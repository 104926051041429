import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { AppBar, Toolbar, Hidden, Drawer, IconButton, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from './Logo'
import MenuList from './MenuList'
import UserPanel from './UserPanel'

const useStyles = makeStyles((theme) => ({
  appBarContainer: {
    paddingRight: '24px',
    paddingLeft: '24px',
    // backgroundColor: '#FF223D',
    backgroundColor: '#FF8FC1',
  },
  barButton: {
    paddingRight: 12,
    display: 'flex',
    '& .icon': {
      width: '20px',
    },
    '& ul': {
      display: 'flex',
    },
    '& .listButton': {
      color: '#000',
      padding: '0 12px',
      borderLeft: '2px solid #fff',
    },
    '& .listButton.first': {
      borderLeft: 0,
      paddingLeft: 0,
    },

    '& .listButton[aria-expanded=true]': {
      backgroundColor: 'rgba(43, 28, 47, 0.33)',
      color: '#fff',
    },
    '& .listIcon': {
      minWidth: '18px',
    },
    '& .listText': {},
    '& .listIcon svg': {
      fontSize: '16px',
      color: 'rgba(43, 28, 47, 1)',
    },
    '& .listText span': {
      fontSize: '16px',
      lineHeight: '16px',
      color: 'rgba(43, 28, 47, 1)',
      fontFamily: "'Dosis', sans-serif",
      fontWeight: 800,
    },
  },
  grow: {
    flexGrow: 1,
  },
  halfGrow: {
    flexGrow: 0.5,
  },
  [theme.breakpoints.up('lg')]: {
    barButton: {
      '& .listButton': {},
      '& .listIcon': {
        minWidth: '24px',
      },
      '& .listText': {},
      '& .listIcon svg': {
        fontSize: '20px',
      },
      '& .listText span': {
        fontSize: '20px',
        lineHeight: '20px',
      },
    },
  },
  [theme.breakpoints.up('xl')]: {
    barButton: {
      '& .listButton': {},
      '& .listIcon': {
        minWidth: '24px',
      },
      '& .listText': {},
      '& .listIcon svg': {
        fontSize: '24px',
      },
      '& .listText span': {
        fontSize: '24px',
        lineHeight: '24px',
      },
    },
  },
}))

const HeaderBar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const classes = useStyles()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <AppBar>
      <Box className={classes.appBarContainer}>
        <Toolbar disableGutters>
          <Hidden lgUp>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} size="large">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Logo />
          <Box className={classes.halfGrow} />
          <Hidden lgDown implementation="css">
            <Box className={classes.barButton}>
              <img className="icon" src="/images/icons/white-paw.svg" alt="white paw" />
              <MenuList />
              <img className="icon" src="/images/icons/white-paw.svg" alt="white paw" />
            </Box>
          </Hidden>
          <Box className={classes.halfGrow} />
          <UserPanel />
        </Toolbar>
        <Hidden lgUp implementation="js">
          <Drawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
            <div>
              <MenuList />
            </div>
          </Drawer>
        </Hidden>
      </Box>
    </AppBar>
  )
}

export default HeaderBar
