import React from 'react'
import bsc, { UseWalletProvider } from '@binance-chain/bsc-use-wallet'
import { Provider } from 'react-redux'
import getRpcUrl from 'utils/getRpcUrl'
import ThemeContextProvider from 'contexts/ThemeContext'
import { BlockContextProvider } from 'contexts/BlockContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import store from 'state'
import GlobalCss from './style/Global'

const Providers: React.FC = ({ children }) => {
  const rpcUrl = getRpcUrl()

  return (
    <Provider store={store}>
      <ThemeContextProvider>
        <GlobalCss />
        <UseWalletProvider
          chainId={parseInt(process.env.REACT_APP_CHAIN_ID)}
          connectors={{
            walletconnect: { rpcUrl },
            bsc,
          }}
        >
          <BlockContextProvider>
            <RefreshContextProvider>{children}</RefreshContextProvider>
          </BlockContextProvider>
        </UseWalletProvider>
      </ThemeContextProvider>
    </Provider>
  )
}

export default Providers
