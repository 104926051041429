import poolsConfig from 'config/constants/pools'
import masterChefABI from 'config/abi/masterChef.json'
import smoyABI from 'config/abi/smoyToken.json'
import wbnbABI from 'config/abi/weth.json'
import { QuoteToken } from 'config/constants/types'
import { SMOY_PER_BLOCK } from 'config'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })
  const callsRewardPerBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'rewardPerBlock',
    }
  })
  const starts = await multicall(masterChefABI, callsStartBlock)
  const ends = await multicall(masterChefABI, callsEndBlock)
  const rewards = await multicall(masterChefABI, callsRewardPerBlock)

  return poolsWithEnd.map((poolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    const rewardPerBlock = rewards[index]
    return {
      sousId: poolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
      rewardPerBlock: new BigNumber(rewardPerBlock).toJSON(),
    }
  })
}

export const fetchPoolsTotalStatking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingTokenName !== QuoteToken.BNB)
  const bnbPool = poolsConfig.filter((p) => p.stakingTokenName === QuoteToken.BNB)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: poolConfig.stakingTokenAddress,
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(smoyABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

export const fetchPoolsInfo = async () => {
  const callsPoolInfo = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'poolInfo',
      params: [poolConfig.sousId],
    }
  })
  const poolsInfo = await multicall(masterChefABI, callsPoolInfo)

  const callsMasterChef = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalAllocPoint',
    }
  })
  const masterChefsInfo = await multicall(masterChefABI, callsMasterChef)

  return poolsConfig.map((poolConfig, index) => {
    const poolInfo = poolsInfo[index]
    const masterChefInfo = masterChefsInfo[index]
    const allocPoint = new BigNumber(poolInfo.allocPoint._hex)
    const totalAllocPoint = new BigNumber(masterChefInfo[0]._hex)
    const tokenPerBlock = allocPoint.div(totalAllocPoint).times(SMOY_PER_BLOCK)
    return {
      sousId: poolConfig.sousId,
      multiplier: `${allocPoint.div(100).toNumber()}x`,
      tokenPerBlock: tokenPerBlock.toNumber(),
    }
  })
}
