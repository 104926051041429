/**
 * Convert ERC721 Token to to Hex String
 * @param {number} tokenId ERC721 TokenId
 * @param {number} digit number of degit to be converted
 * @return {string} Hex String
 */
export const toNumberHex = (tokenId: number, digit: number) => {
  const hex = tokenId.toString(16).padStart(digit, '0')
  const numberText = []
  for (let i = 0; i < hex.length; i++) {
    numberText.push(parseInt(hex[i], 16))
    numberText.push('-')
  }
  numberText.splice(-1, 1)
  return numberText.join('')
}

export default null
