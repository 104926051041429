export type ConnectorId =
  | 'authereum'
  | 'fortmatic'
  | 'frame'
  | 'injected'
  | 'portis'
  | 'squarelink'
  | 'torus'
  | 'walletconnect'
  | 'walletlink'
  | 'bsc'

export interface Config {
  title: string
  icon: string
  connectorId: ConnectorId
}

const connectors: Config[] = [
  {
    title: 'Metamask',
    icon: '/images/wallets/metamask.svg',
    connectorId: 'injected',
  },
  {
    title: 'Trust Wallet',
    icon: '/images/wallets/trustwallet.svg',
    connectorId: 'injected',
  },
  {
    title: 'Wallet Connect',
    icon: '/images/wallets/walletconnect.svg',
    connectorId: 'walletconnect',
  },
  {
    title: 'Binance Chain',
    icon: '/images/wallets/binancewallet.svg',
    connectorId: 'bsc',
  },
]

export const localStorageKey = 'accountStatus'
export default connectors
