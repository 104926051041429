import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
  pawButtton: {
    border: '2px solid #2B1C2F',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 0px #2B1C2F',
    borderRadius: '8px',
    backgroundImage: 'url(/images/BG/BG_Footprint_Button.png)',
    backgroundSize: '100% auto',
    fontSize: '12px',
    lineHeight: '18px',
    padding: '6px 3px',
    fontWeight: 800,
    textTransform: 'uppercase',
    fontFamily: "'Dosis', sans-serif",
    '&:hover': {
      boxShadow: '0px 2px 0px #2B1C2F',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '26px',
      padding: theme.spacing(1.5, 1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '30px',
      padding: theme.spacing(1.5, 2),
    },
  },
}))

const PawButtton = (props) => {
  const classes = useStyles()
  const { color, width, ...other } = props
  let colorCode = '#FFD065'

  if (color === 'blue') {
    colorCode = '#BAE1FF'
  } else if (color === 'gray') {
    colorCode = '#52494C'
  } else if (color === 'pink') {
    colorCode = '#FF8FC1'
  } else if (color === 'pinkB') {
    colorCode = '#FEC2DC'
  } else if (color === 'coralA') {
    colorCode = '#FFA8BE'
  } else if (color === 'coralB') {
    colorCode = '#FFB3BA'
  } else if (color === 'coralC') {
    colorCode = '#FFC6A8'
  } else if (color === 'skyBlue') {
    colorCode = '#A6CCFE'
  } else if (color === 'honey') {
    colorCode = 'rgb(255, 208, 101)'
  } else if (color === 'orange') {
    colorCode = 'rgb(255, 170, 22)'
  } else if (color === 'mintA') {
    colorCode = '#ACFFA5'
  } else if (color === 'mintB') {
    colorCode = '#BAFFC9'
  }

  return (
    <Button
      variant="contained"
      style={{ backgroundColor: colorCode, width }}
      className={classes.pawButtton}
      {...other}
    />
  )
}
export default PawButtton
