import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import vaultLPABI from 'config/abi/vault/vaultLP.json'
import strategyABI from 'config/abi/vault/vaultStrategy.json'
import multicall from 'utils/multicall'
import vaultsConfig from 'config/constants/vaults'
import { getAddress } from 'utils/addressHelpers'
import { getWeb3 } from 'utils/web3'

const web3 = getWeb3()

export const fetchUserLPAllowances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = getAddress(vault.lpAddresses)
    const vaultAddress = getAddress(vault.vaultAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, vaultAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchUserLPTokenBalances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = getAddress(vault.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchUserLPStakedBalances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    return {
      address: getAddress(vault.vaultAddresses),
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawStakedBalances = await multicall(erc20ABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchUserVaultLPAllownaces = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    return {
      address: getAddress(vault.vaultAddresses),
      name: 'allowance',
      params: [account, getAddress(vault.helper)],
    }
  })

  const rawAllowances = await multicall(erc20ABI, calls)
  const parsedAllowances = rawAllowances.map((allownace) => {
    return new BigNumber(allownace[0]._hex).toJSON()
  })
  return parsedAllowances
}

export const fetchUserTokenAllowancesToHelper = async (account: string, tokenAddress: string) => {
  const calls = vaultsConfig.map((vault) => {
    return { address: tokenAddress, name: 'allowance', params: [account, getAddress(vault.helper)] }
  })

  const allowances = await multicall(erc20ABI, calls)
  const parsedAllowances = allowances.map((allowance) => {
    return new BigNumber(allowance).toJSON()
  })
  return parsedAllowances
}

export const fetchUserTokenBalances = async (account: string, tokenAddress: string) => {
  const calls = vaultsConfig.map(() => {
    return { address: tokenAddress, name: 'balanceOf', params: [account] }
  })

  const balances = await multicall(erc20ABI, calls)
  const parsedBalances = balances.map((balance) => {
    return new BigNumber(balance).toJSON()
  })
  return parsedBalances
}

export const fetchUserBNBBalance = async (account: string) => {
  const balance = await web3.eth.getBalance(account)
  return balance
}

export const fetchUserDepositTime = async (account: string) => {
  const calls1 = vaultsConfig.map((vault) => {
    return { address: getAddress(vault.vaultAddresses), name: 'strategy' }
  })
  const strategies = await multicall(vaultLPABI, calls1)
  const calls2 = strategies.map((strategy) => {
    return { address: strategy.toString(), name: 'userDepositTime', params: [account] }
  })
  const _times = await multicall(strategyABI, calls2)
  const times = _times.map((time) => {
    return new BigNumber(time).toNumber()
  })
  return times
}
