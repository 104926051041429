import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  textShadowSpanCover: {
    display: 'inline-block',
    fontFamily: "'Dosis', sans-serif",
    fontWeight: 800,
    '& .white-48-black-4-60': {
      display: 'inline-block',
      fontSize: '24px',
      lineHeight: '30px',
      color: '#fff',
      textShadow:
        'rgb(43, 28, 47) 3px 0px 0px, rgb(43, 28, 47) 2.83487px 0.981584px 0px, rgb(43, 28, 47) 2.35766px 1.85511px 0px, rgb(43, 28, 47) 1.62091px 2.52441px 0px, rgb(43, 28, 47) 0.705713px 2.91581px 0px, rgb(43, 28, 47) -0.287171px 2.98622px 0px, rgb(43, 28, 47) -1.24844px 2.72789px 0px, rgb(43, 28, 47) -2.07227px 2.16926px 0px, rgb(43, 28, 47) -2.66798px 1.37182px 0px, rgb(43, 28, 47) -2.96998px 0.42336px 0px, rgb(43, 28, 47) -2.94502px -0.571704px 0px, rgb(43, 28, 47) -2.59586px -1.50383px 0px, rgb(43, 28, 47) -1.96093px -2.27041px 0px, rgb(43, 28, 47) -1.11013px -2.78704px 0px, rgb(43, 28, 47) -0.137119px -2.99686px 0px, rgb(43, 28, 47) 0.850987px -2.87677px 0px, rgb(43, 28, 47) 1.74541px -2.43999px 0px, rgb(43, 28, 47) 2.44769px -1.73459px 0px, rgb(43, 28, 47) 2.88051px -0.838247px 0px',
    },
    '& .white-48-black-2-60': {
      display: 'flex',
      fontSize: '24px',
      lineHeight: '30px',
      color: '#fff',
      textShadow:
        'rgb(43, 28, 47) 2px 0px 0px, rgb(43, 28, 47) 1.75517px 0.958851px 0px, rgb(43, 28, 47) 1.0806px 1.68294px 0px, rgb(43, 28, 47) 0.141474px 1.99499px 0px, rgb(43, 28, 47) -0.832294px 1.81859px 0px, rgb(43, 28, 47) -1.60229px 1.19694px 0px, rgb(43, 28, 47) -1.97998px 0.28224px 0px, rgb(43, 28, 47) -1.87291px -0.701566px 0px, rgb(43, 28, 47) -1.30729px -1.5136px 0px, rgb(43, 28, 47) -0.421592px -1.95506px 0px, rgb(43, 28, 47) 0.567324px -1.91785px 0px, rgb(43, 28, 47) 1.41734px -1.41108px 0px, rgb(43, 28, 47) 1.92034px -0.558831px 0px',
    },
    '& .white-36-black-4-48': {
      display: 'inline-block',
      fontSize: '36px',
      lineHeight: '48px',
      color: '#fff',
      textShadow:
        'rgb(43, 28, 47) 4px 0px 0px, rgb(43, 28, 47) 3.87565px 0.989616px 0px, rgb(43, 28, 47) 3.51033px 1.9177px 0px, rgb(43, 28, 47) 2.92676px 2.72656px 0px, rgb(43, 28, 47) 2.16121px 3.36588px 0px, rgb(43, 28, 47) 1.26129px 3.79594px 0px, rgb(43, 28, 47) 0.282949px 3.98998px 0px, rgb(43, 28, 47) -0.712984px 3.93594px 0px, rgb(43, 28, 47) -1.66459px 3.63719px 0px, rgb(43, 28, 47) -2.51269px 3.11229px 0px, rgb(43, 28, 47) -3.20457px 2.39389px 0px, rgb(43, 28, 47) -3.69721px 1.52664px 0px, rgb(43, 28, 47) -3.95997px 0.56448px 0px, rgb(43, 28, 47) -3.97652px -0.432781px 0px, rgb(43, 28, 47) -3.74583px -1.40313px 0px, rgb(43, 28, 47) -3.28224px -2.28625px 0px, rgb(43, 28, 47) -2.61457px -3.02721px 0px, rgb(43, 28, 47) -1.78435px -3.57996px 0px, rgb(43, 28, 47) -0.843183px -3.91012px 0px, rgb(43, 28, 47) 0.150409px -3.99717px 0px, rgb(43, 28, 47) 1.13465px -3.8357px 0px, rgb(43, 28, 47) 2.04834px -3.43574px 0px, rgb(43, 28, 47) 2.83468px -2.82216px 0px, rgb(43, 28, 47) 3.44477px -2.03312px 0px, rgb(43, 28, 47) 3.84068px -1.11766px 0px, rgb(43, 28, 47) 3.9978px -0.132717px 0px',
    },
    '& .white-24-black-4-30': {
      display: 'inline-block',
      fontSize: '24px',
      lineHeight: '30px',
      color: '#fff',
      textShadow:
        'rgb(43, 28, 47) 3px 0px 0px, rgb(43, 28, 47) 2.83487px 0.981584px 0px, rgb(43, 28, 47) 2.35766px 1.85511px 0px, rgb(43, 28, 47) 1.62091px 2.52441px 0px, rgb(43, 28, 47) 0.705713px 2.91581px 0px, rgb(43, 28, 47) -0.287171px 2.98622px 0px, rgb(43, 28, 47) -1.24844px 2.72789px 0px, rgb(43, 28, 47) -2.07227px 2.16926px 0px, rgb(43, 28, 47) -2.66798px 1.37182px 0px, rgb(43, 28, 47) -2.96998px 0.42336px 0px, rgb(43, 28, 47) -2.94502px -0.571704px 0px, rgb(43, 28, 47) -2.59586px -1.50383px 0px, rgb(43, 28, 47) -1.96093px -2.27041px 0px, rgb(43, 28, 47) -1.11013px -2.78704px 0px, rgb(43, 28, 47) -0.137119px -2.99686px 0px, rgb(43, 28, 47) 0.850987px -2.87677px 0px, rgb(43, 28, 47) 1.74541px -2.43999px 0px, rgb(43, 28, 47) 2.44769px -1.73459px 0px, rgb(43, 28, 47) 2.88051px -0.838247px 0px',
    },
    '& .white-18-black-2-24': {
      whiteSpace: 'nowrap',
      fontWeight: 800,
      color: '#fff',
      fontSize: '18px',
      lineHeight: '24px',
      textShadow:
        'rgb(43, 28, 47) 2px 0px 0px, rgb(43, 28, 47) 1.75517px 0.958851px 0px, rgb(43, 28, 47) 1.0806px 1.68294px 0px, rgb(43, 28, 47) 0.141474px 1.99499px 0px, rgb(43, 28, 47) -0.832294px 1.81859px 0px, rgb(43, 28, 47) -1.60229px 1.19694px 0px, rgb(43, 28, 47) -1.97998px 0.28224px 0px, rgb(43, 28, 47) -1.87291px -0.701566px 0px, rgb(43, 28, 47) -1.30729px -1.5136px 0px, rgb(43, 28, 47) -0.421592px -1.95506px 0px, rgb(43, 28, 47) 0.567324px -1.91785px 0px, rgb(43, 28, 47) 1.41734px -1.41108px 0px, rgb(43, 28, 47) 1.92034px -0.558831px 0px',
    },
    '& .yellow-48-black-4-60': {
      display: 'inline-block',
      fontSize: '24px',
      lineHeight: '30px',
      color: '#FFD065',
      textShadow:
        'rgb(43, 28, 47) 3px 0px 0px, rgb(43, 28, 47) 2.83487px 0.981584px 0px, rgb(43, 28, 47) 2.35766px 1.85511px 0px, rgb(43, 28, 47) 1.62091px 2.52441px 0px, rgb(43, 28, 47) 0.705713px 2.91581px 0px, rgb(43, 28, 47) -0.287171px 2.98622px 0px, rgb(43, 28, 47) -1.24844px 2.72789px 0px, rgb(43, 28, 47) -2.07227px 2.16926px 0px, rgb(43, 28, 47) -2.66798px 1.37182px 0px, rgb(43, 28, 47) -2.96998px 0.42336px 0px, rgb(43, 28, 47) -2.94502px -0.571704px 0px, rgb(43, 28, 47) -2.59586px -1.50383px 0px, rgb(43, 28, 47) -1.96093px -2.27041px 0px, rgb(43, 28, 47) -1.11013px -2.78704px 0px, rgb(43, 28, 47) -0.137119px -2.99686px 0px, rgb(43, 28, 47) 0.850987px -2.87677px 0px, rgb(43, 28, 47) 1.74541px -2.43999px 0px, rgb(43, 28, 47) 2.44769px -1.73459px 0px, rgb(43, 28, 47) 2.88051px -0.838247px 0px',
    },
    '& .yellow-36-black-4-48': {
      display: 'inline-block',
      fontSize: '36px',
      lineHeight: '48px',
      color: '#FFD065',
      textShadow:
        'rgb(43, 28, 47) 3px 0px 0px, rgb(43, 28, 47) 2.83487px 0.981584px 0px, rgb(43, 28, 47) 2.35766px 1.85511px 0px, rgb(43, 28, 47) 1.62091px 2.52441px 0px, rgb(43, 28, 47) 0.705713px 2.91581px 0px, rgb(43, 28, 47) -0.287171px 2.98622px 0px, rgb(43, 28, 47) -1.24844px 2.72789px 0px, rgb(43, 28, 47) -2.07227px 2.16926px 0px, rgb(43, 28, 47) -2.66798px 1.37182px 0px, rgb(43, 28, 47) -2.96998px 0.42336px 0px, rgb(43, 28, 47) -2.94502px -0.571704px 0px, rgb(43, 28, 47) -2.59586px -1.50383px 0px, rgb(43, 28, 47) -1.96093px -2.27041px 0px, rgb(43, 28, 47) -1.11013px -2.78704px 0px, rgb(43, 28, 47) -0.137119px -2.99686px 0px, rgb(43, 28, 47) 0.850987px -2.87677px 0px, rgb(43, 28, 47) 1.74541px -2.43999px 0px, rgb(43, 28, 47) 2.44769px -1.73459px 0px, rgb(43, 28, 47) 2.88051px -0.838247px 0px',
    },
    '& .yellow-24-black-4-30': {
      display: 'inline-block',
      fontSize: '24px',
      lineHeight: '30px',
      color: '#FFD065',
      textShadow:
        'rgb(43, 28, 47) 3px 0px 0px, rgb(43, 28, 47) 2.83487px 0.981584px 0px, rgb(43, 28, 47) 2.35766px 1.85511px 0px, rgb(43, 28, 47) 1.62091px 2.52441px 0px, rgb(43, 28, 47) 0.705713px 2.91581px 0px, rgb(43, 28, 47) -0.287171px 2.98622px 0px, rgb(43, 28, 47) -1.24844px 2.72789px 0px, rgb(43, 28, 47) -2.07227px 2.16926px 0px, rgb(43, 28, 47) -2.66798px 1.37182px 0px, rgb(43, 28, 47) -2.96998px 0.42336px 0px, rgb(43, 28, 47) -2.94502px -0.571704px 0px, rgb(43, 28, 47) -2.59586px -1.50383px 0px, rgb(43, 28, 47) -1.96093px -2.27041px 0px, rgb(43, 28, 47) -1.11013px -2.78704px 0px, rgb(43, 28, 47) -0.137119px -2.99686px 0px, rgb(43, 28, 47) 0.850987px -2.87677px 0px, rgb(43, 28, 47) 1.74541px -2.43999px 0px, rgb(43, 28, 47) 2.44769px -1.73459px 0px, rgb(43, 28, 47) 2.88051px -0.838247px 0px',
    },
    '& .yellow-18-black-2-24': {
      whiteSpace: 'nowrap',
      color: '#FFD065',
      fontSize: '18px',
      lineHeight: '24px',
      textShadow:
        'rgb(43, 28, 47) 2px 0px 0px, rgb(43, 28, 47) 1.75517px 0.958851px 0px, rgb(43, 28, 47) 1.0806px 1.68294px 0px, rgb(43, 28, 47) 0.141474px 1.99499px 0px, rgb(43, 28, 47) -0.832294px 1.81859px 0px, rgb(43, 28, 47) -1.60229px 1.19694px 0px, rgb(43, 28, 47) -1.97998px 0.28224px 0px, rgb(43, 28, 47) -1.87291px -0.701566px 0px, rgb(43, 28, 47) -1.30729px -1.5136px 0px, rgb(43, 28, 47) -0.421592px -1.95506px 0px, rgb(43, 28, 47) 0.567324px -1.91785px 0px, rgb(43, 28, 47) 1.41734px -1.41108px 0px, rgb(43, 28, 47) 1.92034px -0.558831px 0px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    textShadowSpanCover: {
      '& .white-48-black-2-60': {
        fontSize: '48px',
        lineHeight: '60px',
      },
      '& .white-48-black-4-60': {
        fontSize: '48px',
        lineHeight: '60px',
        textShadow:
          'rgb(43, 28, 47) 5px 0px 0px, rgb(43, 28, 47) 4.90033px 0.993347px 0px, rgb(43, 28, 47) 4.60531px 1.94709px 0px, rgb(43, 28, 47) 4.12668px 2.82321px 0px, rgb(43, 28, 47) 3.48353px 3.58678px 0px, rgb(43, 28, 47) 2.70151px 4.20736px 0px, rgb(43, 28, 47) 1.81179px 4.6602px 0px, rgb(43, 28, 47) 0.849836px 4.92725px 0px, rgb(43, 28, 47) -0.145998px 4.99787px 0px, rgb(43, 28, 47) -1.13601px 4.86924px 0px, rgb(43, 28, 47) -2.08073px 4.54649px 0px, rgb(43, 28, 47) -2.94251px 4.04248px 0px, rgb(43, 28, 47) -3.68697px 3.37732px 0px, rgb(43, 28, 47) -4.28444px 2.57751px 0px, rgb(43, 28, 47) -4.71111px 1.67494px 0px, rgb(43, 28, 47) -4.94996px 0.7056px 0px, rgb(43, 28, 47) -4.99147px -0.291871px 0px, rgb(43, 28, 47) -4.83399px -1.27771px 0px, rgb(43, 28, 47) -4.48379px -2.2126px 0px, rgb(43, 28, 47) -3.95484px -3.05929px 0px, rgb(43, 28, 47) -3.26822px -3.78401px 0px, rgb(43, 28, 47) -2.4513px -4.35788px 0px, rgb(43, 28, 47) -1.53666px -4.75801px 0px, rgb(43, 28, 47) -0.560763px -4.96845px 0px, rgb(43, 28, 47) 0.437495px -4.98082px 0px, rgb(43, 28, 47) 1.41831px -4.79462px 0px, rgb(43, 28, 47) 2.34258px -4.41727px 0px, rgb(43, 28, 47) 3.17346px -3.86382px 0px, rgb(43, 28, 47) 3.87783px -3.15633px 0px, rgb(43, 28, 47) 4.4276px -2.32301px 0px, rgb(43, 28, 47) 4.80085px -1.39708px 0px, rgb(43, 28, 47) 4.98271px -0.415447px 0px',
      },
      '& .yellow-48-black-4-60': {
        fontSize: '48px',
        lineHeight: '60px',
        textShadow:
          'rgb(43, 28, 47) 5px 0px 0px, rgb(43, 28, 47) 4.90033px 0.993347px 0px, rgb(43, 28, 47) 4.60531px 1.94709px 0px, rgb(43, 28, 47) 4.12668px 2.82321px 0px, rgb(43, 28, 47) 3.48353px 3.58678px 0px, rgb(43, 28, 47) 2.70151px 4.20736px 0px, rgb(43, 28, 47) 1.81179px 4.6602px 0px, rgb(43, 28, 47) 0.849836px 4.92725px 0px, rgb(43, 28, 47) -0.145998px 4.99787px 0px, rgb(43, 28, 47) -1.13601px 4.86924px 0px, rgb(43, 28, 47) -2.08073px 4.54649px 0px, rgb(43, 28, 47) -2.94251px 4.04248px 0px, rgb(43, 28, 47) -3.68697px 3.37732px 0px, rgb(43, 28, 47) -4.28444px 2.57751px 0px, rgb(43, 28, 47) -4.71111px 1.67494px 0px, rgb(43, 28, 47) -4.94996px 0.7056px 0px, rgb(43, 28, 47) -4.99147px -0.291871px 0px, rgb(43, 28, 47) -4.83399px -1.27771px 0px, rgb(43, 28, 47) -4.48379px -2.2126px 0px, rgb(43, 28, 47) -3.95484px -3.05929px 0px, rgb(43, 28, 47) -3.26822px -3.78401px 0px, rgb(43, 28, 47) -2.4513px -4.35788px 0px, rgb(43, 28, 47) -1.53666px -4.75801px 0px, rgb(43, 28, 47) -0.560763px -4.96845px 0px, rgb(43, 28, 47) 0.437495px -4.98082px 0px, rgb(43, 28, 47) 1.41831px -4.79462px 0px, rgb(43, 28, 47) 2.34258px -4.41727px 0px, rgb(43, 28, 47) 3.17346px -3.86382px 0px, rgb(43, 28, 47) 3.87783px -3.15633px 0px, rgb(43, 28, 47) 4.4276px -2.32301px 0px, rgb(43, 28, 47) 4.80085px -1.39708px 0px, rgb(43, 28, 47) 4.98271px -0.415447px 0px',
      },
    },
  },
}))

const TextShadow = (props) => {
  const classes = useStyles()
  const { color, colorBorder, size, sizeBorder, lineHeight, children } = props
  const className = `${color}-${size}-${colorBorder}-${sizeBorder}-${lineHeight}`
  return (
    <span className={classes.textShadowSpanCover}>
      <span className={className}>{children}</span>
    </span>
  )
}

export default TextShadow
