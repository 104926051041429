import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'

const useStyles = makeStyles(() => ({
  webBG: {
    backgroundImage: 'url(/images/BG/web-bg-with-paw.png)',
    // backgroundImage: 'url(/images/BG/samoyed-xmas-bg.png)',
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    zIndex: 10,
    opacity: 1,
    '& img': {
      opacity: 0,
    },
  },
}))

const BGThreeSamoy = () => {
  const classes = useStyles()
  return (
    <Box id="BGThreeSamoy" className={classes.webBG}>
      <img alt="preload" src="/images/BG/web-bg-with-paw.png" />
    </Box>
  )
}
export default BGThreeSamoy
