import { configureStore } from '@reduxjs/toolkit'
import smoyPriceReducer from './smoyPrice'
import farmsReducer from './farms'
import poolsReducer from './pools'
import vaultsReducer from './vaults'
import godfatherReducer from './godfatherStorage'
import PuppyReducer from './puppyStorage'
import miniGameReducer from './samoyedGame/miniGame'
import lotteryReducer from './samoyedGame/lottery'
import marketplaceReducer from './marketplace'
import mintPuppyGameReducer from './samoyedGame/mintPuppyGame'

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  reducer: {
    smoyPrice: smoyPriceReducer,
    farms: farmsReducer,
    pools: poolsReducer,
    vaults: vaultsReducer,
    godfathers: godfatherReducer,
    puppys: PuppyReducer,
    miniGames: miniGameReducer,
    lotteries: lotteryReducer,
    marketplace: marketplaceReducer,
    mintPuppyGame: mintPuppyGameReducer,
  },
})
