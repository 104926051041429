import contracts from './contracts'
import vaultContracts from './vaultContracts'
import { VaultConfig, QuoteToken } from './types'
import { biswap, killswitch, samoyed } from './vaultPlatforms'

const testnetVaults: VaultConfig[] = [
  {
    ...samoyed,
    order: 0,
    pid: 0,
    vaultSymbol: `Vault-${QuoteToken.SMOY}`,
    lpSymbol: QuoteToken.SMOY,
    vaultAddresses: vaultContracts.SMOYxSmoyVault,
    lpAddresses: contracts.smoy,
    tokenSymbol: QuoteToken.SMOY,
    tokenAddresses: contracts.smoy,
    quoteTokenSymbol: QuoteToken.SMOY,
    quoteTokenAddresses: contracts.smoy,

    // Override helper
    helper: vaultContracts.smoyVaultHelper,
    isToken: true,
  },
  {
    ...samoyed,
    order: 1,
    pid: 1,
    vaultSymbol: `Vault-${QuoteToken.SMOYBNB}`,
    lpSymbol: QuoteToken.SMOYBNB,
    vaultAddresses: vaultContracts.SMOYxSmoyBnbVault,
    lpAddresses: contracts.smoyBnbLP,
    tokenSymbol: QuoteToken.SMOY,
    tokenAddresses: contracts.smoy,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAddresses: contracts.wbnb,
  },
  {
    ...samoyed,
    order: 2,
    pid: 2,
    vaultSymbol: `Vault-${QuoteToken.SMOYBUSD}`,
    lpSymbol: QuoteToken.SMOYBUSD,
    vaultAddresses: vaultContracts.SMOYxSmoyBusdVault,
    lpAddresses: contracts.smoyBusdLP,
    tokenSymbol: QuoteToken.SMOY,
    tokenAddresses: contracts.smoy,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAddresses: contracts.busd,
  },
  {
    ...samoyed,
    order: 3,
    pid: 3,
    vaultSymbol: `Vault-${QuoteToken.BNBBUSD}`,
    lpSymbol: QuoteToken.BNBBUSD,
    vaultAddresses: vaultContracts.SMOYxBnbBusdVault,
    lpAddresses: contracts.bnbBusdLP,
    tokenSymbol: QuoteToken.BNB,
    tokenAddresses: contracts.wbnb,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAddresses: contracts.busd,
  },
]

const mainnetVaults: VaultConfig[] = [
  {
    ...biswap,
    order: 20,
    pid: 76,
    vaultSymbol: `Vault-${QuoteToken.AVAXBNB}`,
    lpSymbol: QuoteToken.AVAXBNB,
    vaultAddresses: vaultContracts.BSWxAvaxBnbVault,
    lpAddresses: vaultContracts.BSWxAvaxBnbLP,
    tokenSymbol: QuoteToken.AVAX,
    tokenAddresses: vaultContracts.avaxToken,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAddresses: contracts.wbnb,
  },
  {
    ...biswap,
    order: 21,
    pid: 3,
    vaultSymbol: `Vault-${QuoteToken.BNBBUSD}`,
    lpSymbol: QuoteToken.BNBBUSD,
    vaultAddresses: vaultContracts.BSWxBnbBusdVault,
    lpAddresses: vaultContracts.BSWxBnbBusdLP,
    tokenSymbol: QuoteToken.BNB,
    tokenAddresses: contracts.wbnb,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAddresses: contracts.busd,
  },
  {
    ...biswap,
    order: 22,
    pid: 92,
    vaultSymbol: `Vault-${QuoteToken.GALABNB}`,
    lpSymbol: QuoteToken.GALABNB,
    vaultAddresses: vaultContracts.BSWxGalaBnbVault,
    lpAddresses: vaultContracts.BSWxGalaBnbLP,
    tokenSymbol: QuoteToken.GALA,
    tokenAddresses: vaultContracts.galaToken,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAddresses: contracts.wbnb,
  },
  {
    ...biswap,
    order: 23,
    pid: 70,
    vaultSymbol: `Vault-${QuoteToken.MATICBNB}`,
    lpSymbol: QuoteToken.MATICBNB,
    vaultAddresses: vaultContracts.BSWxMaticBnbVault,
    lpAddresses: vaultContracts.BSWxMaticBnbLP,
    tokenSymbol: QuoteToken.MATIC,
    tokenAddresses: vaultContracts.maticToken,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAddresses: contracts.wbnb,
  },
  {
    ...biswap,
    order: 24,
    pid: 1,
    vaultSymbol: `Vault-${QuoteToken.USDTBUSD}`,
    lpSymbol: QuoteToken.USDTBUSD,
    vaultAddresses: vaultContracts.BSWxUsdtBusdVault,
    lpAddresses: vaultContracts.BSWxUsdtBusdLP,
    tokenSymbol: QuoteToken.USDT,
    tokenAddresses: contracts.usdt,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAddresses: contracts.busd,
  },
  {
    ...killswitch,
    order: 999,
    pid: 52,
    vaultSymbol: `Vault-${QuoteToken.SMOY}`,
    lpSymbol: QuoteToken.SMOY,
    vaultAddresses: vaultContracts.KSWxSmoyVault,
    lpAddresses: contracts.smoy,
    tokenSymbol: QuoteToken.SMOY,
    tokenAddresses: contracts.smoy,
    quoteTokenSymbol: QuoteToken.SMOY,
    quoteTokenAddresses: contracts.smoy,

    kswIzlude: vaultContracts.KSWxSmoyIzlude,
    finalMasterchefABI: 'samoyed',
    finalMasterchefAddresses: contracts.samoyedMasterChef,
    finalMasterchefpid: 0,
    isToken: true,
  },

  // {
  //   ...babyswap,
  //   order: 30,
  //   pid: 110,
  //   vaultSymbol: `Vault-${QuoteToken.GALAUSDT}`,
  //   lpSymbol: QuoteToken.GALAUSDT,
  //   vaultAddresses: vaultContracts.BABYxGalaUsdtVault,
  //   lpAddresses: vaultContracts.BABYxGalaUsdtLP,
  //   tokenSymbol: QuoteToken.GALA,
  //   tokenAddresses: vaultContracts.galaToken,
  //   quoteTokenSymbol: QuoteToken.USDT,
  //   quoteTokenAddresses: contracts.usdt,
  // },
  // {
  //   ...babyswap,
  //   order: 31,
  //   pid: 17,
  //   vaultSymbol: `Vault-${QuoteToken.MATICUSDT}`,
  //   lpSymbol: QuoteToken.MATICUSDT,
  //   vaultAddresses: vaultContracts.BABYxMaticUsdtVault,
  //   lpAddresses: vaultContracts.BABYxMaticUsdtLP,
  //   tokenSymbol: QuoteToken.MATIC,
  //   tokenAddresses: vaultContracts.maticToken,
  //   quoteTokenSymbol: QuoteToken.USDT,
  //   quoteTokenAddresses: contracts.usdt,
  // },
]

const chainId = process.env.REACT_APP_CHAIN_ID
const vaults = chainId === '56' ? mainnetVaults : testnetVaults

export default vaults
