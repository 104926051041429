import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  notificationBar: {
    padding: theme.spacing(1),
    backgroundColor: '#BAFFC9',
    fontFamily: "'Dosis', sans-serif",
    fontWeight: 800,
    zIndex: 11,
    fontSize: '18px',
    textAlign: 'center',
  },
  barButton: {
    paddingRight: 12,
    display: 'flex',
    '& .icon': {
      width: '20px',
    },
    '& ul': {
      display: 'flex',
    },
    '& .listButton': {
      color: '#000',
      padding: '0 12px',
      borderLeft: '2px solid #fff',
    },
    '& .listButton.first': {
      borderLeft: 0,
      paddingLeft: 0,
    },

    '& .listButton[aria-expanded=true]': {
      backgroundColor: 'rgba(43, 28, 47, 0.33)',
      color: '#fff',
    },
    '& .listIcon': {
      minWidth: '18px',
    },
    '& .listText': {},
    '& .listIcon svg': {
      fontSize: '16px',
      color: 'rgba(43, 28, 47, 1)',
    },
    '& .listText span': {
      fontSize: '16px',
      lineHeight: '16px',
      color: 'rgba(43, 28, 47, 1)',
      fontFamily: "'Dosis', sans-serif",
      fontWeight: 800,
    },
  },
  grow: {
    flexGrow: 1,
  },
  halfGrow: {
    flexGrow: 0.5,
  },
}))

const NotificationBar = () => {
  const classes = useStyles()

  return (
    <Box className={classes.notificationBar}>
      We have already completed a snapshot of the Puppy NFT Holder on 5/9/2022.
      <br />
      All 170 Puppy NFT will be eligible for free mint for the next feature. TBA!{' '}
      <img style={{ height: '24px' }} src="/images/Samoyed-with-glass.png" alt="Samoyed-with-glass" />
    </Box>
  )
}

export default NotificationBar
