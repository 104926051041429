import { sortBy } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import multicall from 'utils/multicall'
import { LotteryState, LotteryResultState, LotteryRewardTypeEnum } from 'state/types'
import { format } from 'date-fns'
import godfatherLottoCheckV2 from 'config/abi/lotto_GodfatherLottoCheckV2.json'
import puppyLottoCheckV2 from 'config/abi/lotto_PuppyLottoCheckV2.json'
import moyLottoCheckV2 from 'config/abi/lotto_MoyLottoCheckV2.json'
import samoyedLottoAwardV2 from 'config/abi/lotto_SamoyedLottoAwardV2.json'
import erc20ABI from 'config/abi/erc20.json'
import { toNumberHex } from 'utils/nftUtils'

import {
  getLottoAwardAddress,
  getLottoCheckGodfatherAddress,
  getLottoCheckPuppyAddress,
  getLottoCheckMoyAddress,
  getSmoyAddress,
} from 'utils/addressHelpers'

const initialState: LotteryState = {
  currentRound: 0,
  currentAwardRound: 0,
  latestAwardGodfatherString: '??-??-??-??-??-??',
  latestAwardPuppyString: '??-??',
  latestAwardMoyString: '??',
  latestAwardDateString: '',
  godfatherResult: [],
  puppyResult: [],
  moyResult: [],
  moyLottoCheckSmoyBalance: 0,
  godfatherLottoCheckSmoyBalance: 0,
}

// function gongUpdateState(state, _lottoNumber, _pack) {
//   const lottoNumberFound = state.puppyOwned.find((element) => element.lottoNumber === _lottoNumber)
//   let puppyReturnObject
//   if (lottoNumberFound !== undefined) {
//     const packFound = lottoNumberFound.pack.find((element) => element === _pack)
//     if (packFound !== undefined) {
//       puppyReturnObject = undefined
//     } else {
//       const packClone = lottoNumberFound.pack.slice()
//       packClone.push(_pack)
//       puppyReturnObject = [{ lottoNumber: _lottoNumber, pack: packClone }]
//     }
//   } else {
//     puppyReturnObject = [{ lottoNumber: _lottoNumber, pack: [_pack] }]
//   }
//   return puppyReturnObject
// }

export const miniGameSlice = createSlice({
  name: 'LotteryRecucer',
  initialState,
  reducers: {
    setLotteryPublicInfo: (state, action) => {
      const data: LotteryState = action.payload
      return { ...state, ...data }
    },

    deleteAllResult: (state, action) => {
      // console.log('Here')
      const tokenType: number = action.payload
      // console.log('delete tokenType: ', tokenType)
      let varName
      if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
        varName = 'godfatherResult'
      } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
        varName = 'puppyResult'
      } else if (tokenType === LotteryRewardTypeEnum.MOY) {
        varName = 'moyResult'
      } else {
        throw new Error('Unsupported TokenType')
      }
      return {
        ...state,
        [varName]: [],
        // [varName]: [
        //   {
        //     round: -1,
        //     result: 'reloading',
        //     awardTimestamp: 0,
        //     validTokenId: 0,
        //     isRoundWon: false,
        //     wonTicket: [],
        //   },
        //   {
        //     round: -1,
        //     result: 'reloading',
        //     awardTimestamp: 0,
        //     validTokenId: 0,
        //     isRoundWon: false,
        //     wonTicket: [],
        //   },
        // ],
      }
    },

    setGodfatherPreviousRoom: (state, action) => {
      const data: LotteryResultState = action.payload.latestRoundResult
      const { limitResult } = action.payload
      let _rounds = state.godfatherResult.filter((i) => i.round !== data.round)
      _rounds.push(data)
      _rounds = sortBy(_rounds, ['round']).reverse().slice(0, limitResult)
      return { ...state, godfatherResult: [..._rounds] }
    },
    setPuppyPreviousRoom: (state, action) => {
      const data: LotteryResultState = action.payload.latestRoundResult
      const { limitResult } = action.payload
      let _rounds = state.puppyResult.filter((i) => i.round !== data.round)
      _rounds.push(data)
      _rounds = sortBy(_rounds, ['round']).reverse().slice(0, limitResult)
      return { ...state, puppyResult: [..._rounds] }
    },
    setMoyPreviousRoom: (state, action) => {
      const data: LotteryResultState = action.payload.latestRoundResult
      const { limitResult } = action.payload
      let _rounds = state.moyResult.filter((i) => i.round !== data.round)
      _rounds.push(data)
      _rounds = sortBy(_rounds, ['round']).reverse().slice(0, limitResult)
      return { ...state, moyResult: [..._rounds] }
    },
  },
})

// Actions
export const {
  setLotteryPublicInfo,
  setGodfatherPreviousRoom,
  setPuppyPreviousRoom,
  setMoyPreviousRoom,
  deleteAllResult,
} = miniGameSlice.actions

export const fetchLotteryPublicInfo = () => async (dispatch) => {
  // console.log('Call ME HelloWorld')
  // getLottoCheckGodfatherAddress
  const lottoAwardAddress = getLottoAwardAddress()
  const [_awardCounter] = await multicall(samoyedLottoAwardV2, [
    { address: lottoAwardAddress, name: 'getAwardCounter', params: [] },
  ])

  const smoyAddress = getSmoyAddress()
  const godfatherLottoCheckV2Address = getLottoCheckGodfatherAddress()
  const moyLottocheckV2Address = getLottoCheckMoyAddress()
  const [tmpGodfatherSmoyBalance, tmpMoySmoyBalance] = await multicall(erc20ABI, [
    { address: smoyAddress, name: 'balanceOf', params: [godfatherLottoCheckV2Address] },
    { address: smoyAddress, name: 'balanceOf', params: [moyLottocheckV2Address] },
  ])

  const godfatherLottoCheckSmoyBalance = parseInt(
    ethers.utils.formatEther(new BigNumber(tmpGodfatherSmoyBalance[0]._hex).toString(10))
  )
  const moyLottoCheckSmoyBalance = parseInt(
    ethers.utils.formatEther(new BigNumber(tmpMoySmoyBalance[0]._hex).toString(10))
  )

  const currentRound = _awardCounter.latestRound
  const currentAwardRound = _awardCounter.latestCompletedRound

  let latestAwardGodfatherString = ''
  let latestAwardPuppyString = ''
  let latestAwardMoyString = ''
  let latestAwardDateString = ''
  if (currentAwardRound > 0) {
    const roundNo = currentAwardRound - 1
    const [_lottoResult] = await multicall(samoyedLottoAwardV2, [
      { address: lottoAwardAddress, name: 'getAwardResultObject', params: [roundNo] },
    ])
    latestAwardDateString = format(new Date(_lottoResult.timestamp * 1000), 'yyyy-MM-dd')
    let wonNumber
    let modNumber
    modNumber = 16 ** 6
    wonNumber = _lottoResult.wonNumber % modNumber
    latestAwardGodfatherString = toNumberHex(wonNumber, 6)
    modNumber = 16 ** 2
    wonNumber = _lottoResult.wonNumber % modNumber
    latestAwardPuppyString = toNumberHex(wonNumber, 2)
    modNumber = 16 ** 1
    wonNumber = _lottoResult.wonNumber % modNumber
    latestAwardMoyString = toNumberHex(wonNumber, 1)
  }

  dispatch(
    setLotteryPublicInfo({
      currentRound,
      currentAwardRound,
      godfatherLottoCheckSmoyBalance,
      moyLottoCheckSmoyBalance,
      latestAwardGodfatherString,
      latestAwardPuppyString,
      latestAwardMoyString,
      latestAwardDateString,
    })
  )
}

export const fetchGodfatherPreviousResult = (roundNo: number) => async (dispatch) => {
  // console.log('FetchPrevious')
  // console.log(roundNo)
  if (roundNo < 0) return
  const lottoCheckGodfatherAddress = getLottoCheckGodfatherAddress()
  const [_lottoResultGodfather] = await multicall(godfatherLottoCheckV2, [
    { address: lottoCheckGodfatherAddress, name: 'getLottoResultByRound', params: [roundNo] },
  ])
  // console.log(_lottoResultGodfather)
  const godfatherLatestRound = {
    round: new BigNumber(_lottoResultGodfather.round._hex).toNumber(),
    result: _lottoResultGodfather.wonNumber._hex,
  }
  // console.log(godfatherLatestRound)
  dispatch(setGodfatherPreviousRoom(godfatherLatestRound))
}

// async function gongTest() {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve(Math.floor(Math.random() * 5))
//     }, 2000)
//   })
// }

// async function getGongTest(index, gongTestValue) {
//   const res = await gongTest()
//   gongTestValue[index] = res
//   return res
// }

const fetchIsClaimed = async (
  tokenType,
  _tokenId,
  packSize,
  validTokenId,
  lottoCheckAddress,
  roundNo,
  _wonTicketArray
) => {
  let _lottoPack
  let _lottoNumber
  let _isTicketValid = false
  let _isTicketWon = false
  let _isTicketClaimed = true
  const _tokenIdBase16 = _tokenId.toString(16)
  // console.log('tokenId: ', _tokenId)
  // console.log('_tokenIdBase16: ', _tokenIdBase16)
  const tokenIdNumber = parseInt(_tokenId)
  let lottoCheckABI
  if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
    _lottoPack = 0
    _lottoNumber = _tokenId
    // console.log('_lottoNumber: ', parseInt(_lottoNumber))
    // console.log('validTokenId: ', validTokenId)

    _lottoNumber = toNumberHex(_tokenId, 6)
    if (validTokenId === tokenIdNumber) {
      _isTicketValid = true
      _isTicketWon = true
    }
    lottoCheckABI = godfatherLottoCheckV2
  } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
    _lottoPack = Math.floor(_tokenId / packSize)
    // _lottoNumber = (_tokenId % packSize).toString(16)
    _lottoNumber = toNumberHex(_tokenId, 2)
    if (tokenIdNumber <= validTokenId) {
      _isTicketValid = true
      _isTicketWon = true
    }
    lottoCheckABI = puppyLottoCheckV2
  } else if (tokenType === LotteryRewardTypeEnum.MOY) {
    _lottoPack = Math.floor(_tokenId / packSize)
    // _lottoNumber = (_tokenId % packSize).toString(16)
    _lottoNumber = toNumberHex(_tokenId, 1)
    if (tokenIdNumber <= validTokenId) {
      _isTicketValid = true
      _isTicketWon = true
    }
    lottoCheckABI = moyLottoCheckV2
  } else {
    throw new Error('Unsupported TokenType')
  }

  if (_isTicketWon) {
    const callsClaimed = [
      { address: lottoCheckAddress, name: 'isTokenAlreadyClaimedReward', params: [roundNo, _tokenId] },
    ]
    // console.log('RondNo: ', roundNo)
    // console.log('_tokenId: ', _tokenId)

    try {
      const [_claimed] = await multicall(lottoCheckABI, callsClaimed)
      _isTicketClaimed = _claimed[0]

      if (!_isTicketClaimed) {
        const _wonTicket = {
          tokenId: _tokenIdBase16,
          lottoPack: _lottoPack,
          lottoNumber: _lottoNumber,
          isTicketWon: _isTicketWon,
          isTicketClaimed: _isTicketClaimed,
          isTicketValid: _isTicketValid,
        }

        _wonTicketArray.push(_wonTicket)
      }
    } catch (e) {
      console.error('Error: ', e)
    }
  }
}

export const fetchPreviousResult =
  (tokenType: number, roundNo: number, historyItemPerPage: number, tokenIdArray?) => async (dispatch) => {
    // console.log('FetchPrevious')
    // console.log(roundNo)
    if (roundNo < 0) return

    const lottoAwardAddress = getLottoAwardAddress()
    // const [_awardCounter] = await multicall(lottoAwardABI, [
    //   { address: lottoAwardAddress, name: 'getAwardCounter', params: [] },
    // ])

    const [_lottoResult] = await multicall(samoyedLottoAwardV2, [
      { address: lottoAwardAddress, name: 'getAwardResultObject', params: [roundNo] },
    ])

    // uint8 round,
    // uint32 timestamp,
    // uint24 wonNumber,
    // uint24 godfatherValidToken,
    // uint16 puppyValidToken,
    // uint8 winnerSharingQuantity

    const _awardTimestamp = _lottoResult.timestamp
    let wonNumber
    let validTokenId

    let lottoCheckAddress
    let modNumber
    let packSize
    let wonResultHex
    if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
      lottoCheckAddress = getLottoCheckGodfatherAddress()
      modNumber = 16 ** 6
      packSize = -1
      wonNumber = _lottoResult.wonNumber % modNumber
      validTokenId = _lottoResult.godfatherValidToken
      wonResultHex = toNumberHex(wonNumber, 6)
    } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
      lottoCheckAddress = getLottoCheckPuppyAddress()
      modNumber = 16 ** 2
      packSize = 256
      wonNumber = _lottoResult.wonNumber % modNumber
      validTokenId = _lottoResult.puppyValidToken
      wonResultHex = toNumberHex(wonNumber, 2)
    } else if (tokenType === LotteryRewardTypeEnum.MOY) {
      lottoCheckAddress = getLottoCheckMoyAddress()
      modNumber = 16 ** 1
      packSize = 256
      wonNumber = _lottoResult.wonNumber % modNumber
      validTokenId = _lottoResult.puppyValidToken
      wonResultHex = toNumberHex(wonNumber, 1)
    } else {
      throw new Error('Unsupported TokenType')
    }

    // console.log('lottoResult:', _lottoResult)

    // console.log('_awardTimeStemp:', _awardTimestamp)
    let _isRoundWon = false
    const _wonTicketArray = []
    if (tokenIdArray !== undefined) {
      let wonTokenIdArray

      if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
        wonTokenIdArray = tokenIdArray.filter((element) => new BigNumber(element).isEqualTo(wonNumber))
        // console.log('wonNumber: ', wonNumber)
        // console.log('wonTokenIdArray: ', wonTokenIdArray)
      } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
        wonTokenIdArray = tokenIdArray.filter((element) => new BigNumber(element).mod(modNumber).isEqualTo(wonNumber))
      } else if (tokenType === LotteryRewardTypeEnum.MOY) {
        wonTokenIdArray = tokenIdArray.filter((element) => new BigNumber(element).mod(modNumber).isEqualTo(wonNumber))
      }
      // console.log('chainLinkWonNumber:', wonNumber)
      // console.log('myWonTicket', wonTokenIdArray)
      const promisesFetchIsClaimedAll = []
      // const gongTestValue = []
      if (wonTokenIdArray.length > 0) {
        _isRoundWon = true
        wonTokenIdArray.forEach(async (_tokenId) => {
          promisesFetchIsClaimedAll.push(
            fetchIsClaimed(tokenType, _tokenId, packSize, validTokenId, lottoCheckAddress, roundNo, _wonTicketArray)
          )
          // promisesFetchIsClaimedAll.push(getGongTest(index, gongTestValue))
        })
      }
      Promise.all([...promisesFetchIsClaimedAll]).then(() => {
        // console.log('gongTestValue: ', gongTestValue)
        // promisesFetchIsClaimedAll.forEach((element, index, array) => {
        //   console.log(element)
        // })
        // _wonTicketArray.push(_wonTicket)
        // console.log('_wonTicketArray: ', _wonTicketArray)
        // const _wonTicketArraySort = sortBy(_wonTicketArray, ['tokenId'])()

        const _wonTicketArraySort = sortBy(_wonTicketArray, ['tokenId'])

        const _latestRoundResult = {
          round: _lottoResult.round,
          result: wonResultHex,
          awardTimestamp: _awardTimestamp,
          validTokenId: validTokenId.toString(16),
          isRoundWon: _isRoundWon,
          wonTicket: [..._wonTicketArraySort],
        }
        const dataPayload = {
          limitResult: historyItemPerPage,
          latestRoundResult: _latestRoundResult,
        }
        // console.log('latestRoundResult: ', _latestRoundResult)
        if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
          dispatch(setGodfatherPreviousRoom(dataPayload))
        } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
          dispatch(setPuppyPreviousRoom(dataPayload))
        } else if (tokenType === LotteryRewardTypeEnum.MOY) {
          dispatch(setMoyPreviousRoom(dataPayload))
        } else {
          throw new Error('Unsupported TokenType')
        }
      })
    } else {
      const _latestRoundResult = {
        round: _lottoResult.round,
        result: wonResultHex,
        awardTimestamp: _awardTimestamp,
        validTokenId: validTokenId.toString(16),
        isRoundWon: false,
        wonTicket: [],
      }
      const dataPayload = {
        limitResult: historyItemPerPage,
        latestRoundResult: _latestRoundResult,
      }
      // console.log('latestRoundResult: ', _latestRoundResult)
      if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
        dispatch(setGodfatherPreviousRoom(dataPayload))
      } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
        dispatch(setPuppyPreviousRoom(dataPayload))
      } else if (tokenType === LotteryRewardTypeEnum.MOY) {
        dispatch(setMoyPreviousRoom(dataPayload))
      } else {
        throw new Error('Unsupported TokenType')
      }
    }
  }

export const sentDeleteAllResult = (tokenType: number) => async (dispatch) => {
  if (tokenType === LotteryRewardTypeEnum.GODFATHER) {
    dispatch(deleteAllResult(tokenType))
  } else if (tokenType === LotteryRewardTypeEnum.PUPPY) {
    dispatch(deleteAllResult(tokenType))
  } else if (tokenType === LotteryRewardTypeEnum.MOY) {
    dispatch(deleteAllResult(tokenType))
  } else {
    throw new Error('Unsupported TokenType')
  }
}

// export const fetchPreviousResult = (roundNo: number, account?) => async (dispatch) => {
//   console.log('FetchPrevious')
//   console.log(roundNo)
//   if (roundNo < 0) return
//   const lottoCheckGodfatherAddress = getLottoCheckGodfatherAddress()
//   const lottoCheckPuppyAddress = getLottoCheckPuppyAddress()
//   const lottoCheckMoyAddress = getLottoCheckMoyAddress()
//   const [_lottoResultGodfather, _lottoResultPuppy, _lottoResultMoy] = await multicall(lottoCheckABI, [
//     { address: lottoCheckGodfatherAddress, name: 'getLottoResultByRound', params: [roundNo] },
//     { address: lottoCheckPuppyAddress, name: 'getLottoResultByRound', params: [roundNo] },
//     { address: lottoCheckMoyAddress, name: 'getLottoResultByRound', params: [roundNo] },
//   ])
//   // console.log(_lottoResultGodfather)
//   // console.log(_lottoResultPuppy)
//   // console.log(_lottoResultMoy)

//   const godfatherLatestRound = {
//     round: new BigNumber(_lottoResultGodfather.round._hex).toNumber(),
//     result: _lottoResultGodfather.wonNumber._hex,
//   }
//   console.log(godfatherLatestRound)
//   const puppyLatestRound = {
//     round: new BigNumber(_lottoResultPuppy.round._hex).toNumber(),
//     result: _lottoResultPuppy.wonNumber._hex,
//   }
//   const moyLatestRound = {
//     round: new BigNumber(_lottoResultMoy.round._hex).toNumber(),
//     result: _lottoResultMoy.wonNumber._hex,
//   }

//   dispatch(
//     setLotteryPreviousResult({
//       godfatherResult: [godfatherLatestRound],
//       puppyResult: [puppyLatestRound],
//       moyResult: [moyLatestRound],
//     })
//   )
// }

// export const fetchMiniGamePreviousRoom = (roomNo: number, account?) => async (dispatch) => {
//   if (roomNo < 0) return

//   const miniGameAddress = getMiniGameAddress()
//   const [_maxSeat, _nextWinnerDrawRoomNo, _winner] = await multicall(miniGameABI, [
//     { address: miniGameAddress, name: 'maxSeat', params: [] },
//     { address: miniGameAddress, name: 'nextWinnerDrawRoomNo', params: [] },
//     { address: miniGameAddress, name: 'winner', params: [roomNo] },
//   ])
//   const maxSeat = _maxSeat[0]
//   const nextWinnerDrawRoomNo = new BigNumber(_nextWinnerDrawRoomNo[0]._hex).toNumber()

//   const awaitingWinner = roomNo >= nextWinnerDrawRoomNo
//   const winner = awaitingWinner ? undefined : _winner[0]

//   const calls = []
//   Array.from({ length: maxSeat }, (v, k) => k).forEach((i) => {
//     calls.push({ address: miniGameAddress, name: 'sitAddress', params: [roomNo, i] })
//   })
//   const _seatAddresses = await multicall(miniGameABI, calls)

//   const seatAddresses = _seatAddresses.map((addr, index) => {
//     return {
//       seatNo: index,
//       account: addr.toString(),
//       awaitingWinner,
//       isWon: !awaitingWinner && winner === index,
//       isClaimed: undefined,
//     }
//   })

//   if (account && seatAddresses.some((i) => i.account === account)) {
//     const callsClaimed = [{ address: miniGameAddress, name: 'isAddressClaimedPrize', params: [roomNo, account] }]
//     const [_claimed] = await multicall(miniGameABI, callsClaimed)
//     const _seats: [] = seatAddresses.filter((i) => i.account === account)
//     _seats.forEach((seat: MiniGameSeat) => {
//       const tmp = seat
//       tmp.isClaimed = _claimed[0]
//     })
//   }

//   dispatch(setMiniGamePreviousRoom({ roomNo, seats: seatAddresses, winner }))
// }
// ==================================================================================================
// export const fetchLotteryPublicInfo = () => async (dispatch) => {
//   const chainlinkConsumerAddress = getChainlinkConsumerAddress()

//   const [_currentRound, _currentAwardRound] = await multicall(chainlinkConsumerABI, [
//     { address: chainlinkConsumerAddress, name: 'currentRound', params: [] },
//     { address: chainlinkConsumerAddress, name: 'currentAwardRound', params: [] },
//   ])
//   const currentRound = new BigNumber(_currentRound[0]._hex).toNumber()
//   const currentAwardRound = new BigNumber(_currentAwardRound[0]._hex).toNumber()

//   if (currentRound === currentAwardRound) {
//     const currentRoundMinusOne = currentRound - 1
//     const currentAwardRoundMinusOne = currentAwardRound - 1
//     const [_randomResult] = await multicall(chainlinkConsumerABI, [
//       { address: chainlinkConsumerAddress, name: 'getAwardInfomationByRound', params: [currentAwardRoundMinusOne] },
//     ])
//     const randomResult = new BigNumber(_randomResult.chainLinkRandomResult._hex).toString()
//     dispatch(
//       setLotteryPublicInfo({
//         currentRoundMinusOne,
//         currentAwardRoundMinusOne,
//         randomResults: [{ round: currentAwardRoundMinusOne, result: randomResult }],
//       })
//     )
//   } else if (currentRound < currentAwardRound) {
//     dispatch(
//       setLotteryPublicInfo({
//         currentRound,
//         currentAwardRound,
//         randomResults: [{ round: currentAwardRound, result: '0' }],
//       })
//     )
//   } else {
//     const [_randomResult] = await multicall(chainlinkConsumerABI, [
//       { address: chainlinkConsumerAddress, name: 'getAwardInfomationByRound', params: [currentAwardRound] },
//     ])
//     const randomResult = new BigNumber(_randomResult.chainLinkRandomResult._hex).toString()

//     if (currentRound === 0 && randomResult === '0') {
//       setLotteryPublicInfo({
//         currentRound,
//         currentAwardRound,
//         randomResults: [],
//       })
//     } else {
//       dispatch(
//         setLotteryPublicInfo({
//           currentRound,
//           currentAwardRound,
//           randomResults: [{ round: currentAwardRound, result: randomResult }],
//         })
//       )
//     }
//   }
// }

// export const fetchPreviousResult = (roundNo: number) => async (dispatch) => {
//   if (roundNo < 0) return

//   try {
//     const chainlinkConsumerAddress = getChainlinkConsumerAddress()
//     const [_randomResult] = await multicall(chainlinkConsumerABI, [
//       { address: chainlinkConsumerAddress, name: 'getAwardInfomationByRound', params: [roundNo] },
//     ])
//     const randomResult = new BigNumber(_randomResult.chainLinkRandomResult._hex).toString()
//     dispatch(setLotteryPreviousResult({ round: roundNo, result: randomResult }))
//   } catch {
//     dispatch(setLotteryPreviousResult({ round: roundNo, result: '0' }))
//   }
// }

// export const fetchCurrentRound = async () => {
//   const [_currentRound] = await multicall(chainlinkConsumerABI, [
//     { address: getChainlinkConsumerAddress(), name: 'currentRound', params: [] },
//   ])
//   return new BigNumber(_currentRound[0]._hex).toNumber()
// }

// export const fetchCurrentAwardRound = async () => {
//   const [_currentAwardRound] = await multicall(chainlinkConsumerABI, [
//     { address: getChainlinkConsumerAddress(), name: 'currentAwardRound', params: [] },
//   ])
//   return new BigNumber(_currentAwardRound[0]._hex).toNumber()
// }

// export const fetchAwardInfomationByRound = async (round: number) => {
//   const [_currentAwardRound] = await multicall(chainlinkConsumerABI, [
//     { address: getChainlinkConsumerAddress(), name: 'getAwardInfomationByRound', params: [round] },
//   ])
//   return new BigNumber(_currentAwardRound[0]._hex).toNumber()
// }

export default miniGameSlice.reducer
