import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: QuoteToken.SMOY,
    lpAddresses: contracts.smoy,
    tokenSymbol: QuoteToken.SMOY,
    tokenAddresses: contracts.smoy,
    quoteTokenSymbol: QuoteToken.SMOY,
    quoteTokenAddresses: contracts.smoy,
  },
  {
    pid: 1,
    lpSymbol: QuoteToken.SMOYBNB,
    lpAddresses: contracts.smoyBnbLP,
    tokenSymbol: QuoteToken.BNB,
    tokenAddresses: contracts.wbnb,
    quoteTokenSymbol: QuoteToken.SMOY,
    quoteTokenAddresses: contracts.smoy,
  },
  {
    pid: 2,
    lpSymbol: QuoteToken.SMOYBUSD,
    lpAddresses: contracts.smoyBusdLP,
    tokenSymbol: QuoteToken.BUSD,
    tokenAddresses: contracts.busd,
    quoteTokenSymbol: QuoteToken.SMOY,
    quoteTokenAddresses: contracts.smoy,
  },
  {
    pid: 3,
    lpSymbol: QuoteToken.BNBBUSD,
    lpAddresses: contracts.bnbBusdLP,
    tokenSymbol: QuoteToken.BUSD,
    tokenAddresses: contracts.busd,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAddresses: contracts.wbnb,
  },
  {
    pid: 4,
    lpSymbol: QuoteToken.USDTBUSD,
    lpAddresses: contracts.usdtBusdLP,
    tokenSymbol: QuoteToken.USDT,
    tokenAddresses: contracts.usdt,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAddresses: contracts.busd,
  },
  {
    pid: 5,
    lpSymbol: QuoteToken.KMATICBNB,
    lpAddresses: contracts.kMaticBnbLP,
    tokenSymbol: QuoteToken.KMATIC,
    tokenAddresses: contracts.kMatic,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAddresses: contracts.wbnb,
  },
]

export default farms
