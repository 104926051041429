import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterChefABI from 'config/abi/masterChef.json'
import babyMasterChef from 'config/abi/vault/babyMasterChef.json'
import biswapMasterChef from 'config/abi/vault/biswapMasterChef.json'
import kswMasterChef from 'config/abi/vault/kswMasterchef.json'
import kswIzludeABI from 'config/abi/vault/kswIzlude.json'
import vaultABI from 'config/abi/vault/vaultLP.json'
import vaultStrategyABI from 'config/abi/vault/vaultStrategy.json'
import vaultRaffleABI from 'config/abi/vault/vaultRaffle.json'
import multicall from 'utils/multicall'
import {
  getAddress,
  getWbnbAddress,
  getBusdAddress,
  getPancakeBnbBusdLPAddress,
  getVaultRaffleAddress,
} from 'utils/addressHelpers'
import vaultsConfig from 'config/constants/vaults'
import { QuoteToken } from 'config/constants/types'

export const fetchVaults = async () => {
  const [busdBalance, wbnbBalance] = await multicall(erc20, [
    { address: getBusdAddress(), name: 'balanceOf', params: [getPancakeBnbBusdLPAddress()] },
    { address: getWbnbAddress(), name: 'balanceOf', params: [getPancakeBnbBusdLPAddress()] },
  ])
  const wbnbPrice = new BigNumber(busdBalance).div(wbnbBalance).toNumber()

  const results = await Promise.all(
    vaultsConfig.map(async (vault) => {
      let _masterChefABI = []
      if (vault.masterchefABI === 'babyswap') _masterChefABI = babyMasterChef
      else if (vault.masterchefABI === 'biswap') _masterChefABI = biswapMasterChef
      else if (vault.masterchefABI === 'killswitch') _masterChefABI = kswMasterChef
      else _masterChefABI = masterChefABI

      const lpAddress = getAddress(vault.lpAddresses)
      const masterChefAddress = getAddress(vault.masterchefAddresses)
      const vaultAddress = getAddress(vault.vaultAddresses)

      const [
        quoteTokenBalanceOfLP,
        lpBalanceOfMC,
        lpTotalSupply,
        quoteTokenDecimals,
        rewardLPBalanceOfQuoteToken,
        rewardLPBalanceOfToken,
        rewardTokenDecimals,
      ] = await fetchGeneralInfo(masterChefAddress, vault, lpAddress)

      const [[strategyAddress], vaultTotalSupply, _lpBalanceOfVault] = await multicall(vaultABI, [
        {
          address: vaultAddress,
          name: 'strategy',
        },
        {
          address: vaultAddress,
          name: 'totalSupply',
        },
        {
          address: vaultAddress,
          name: 'balance',
        },
      ])

      const [withdrawalFee, withdrawalMax, earlyWithdrawalFee] = await multicall(vaultStrategyABI, [
        {
          address: strategyAddress,
          name: 'withdrawalFee',
        },
        {
          address: strategyAddress,
          name: 'WITHDRAWAL_MAX',
        },
        {
          address: strategyAddress,
          name: 'earlyWithdrawalFee',
        },
      ])

      // const strategyInfo = await fetchStrategyInfo(masterChefAddress, _masterChefABI, vault, strategyAddress)
      let quoteTokenBalanceOfStrategy = new BigNumber(0)
      let quoteTokenBalanceOfMC = new BigNumber(0)
      if (vault.masterchefABI === 'killswitch') {
        quoteTokenBalanceOfStrategy = new BigNumber(_lpBalanceOfVault).div(new BigNumber(10).pow(quoteTokenDecimals))
        quoteTokenBalanceOfMC = new BigNumber(lpBalanceOfMC._hex).div(new BigNumber(10).pow(quoteTokenDecimals))
      } else if (vault.isToken) {
        // In case of Samoyed's SMOY pot TestNet only
        quoteTokenBalanceOfStrategy = new BigNumber(_lpBalanceOfVault).div(new BigNumber(10).pow(quoteTokenDecimals))
        quoteTokenBalanceOfMC = new BigNumber(lpBalanceOfMC).div(new BigNumber(10).pow(quoteTokenDecimals))
      } else {
        // lpBalanceOfVault = How many LP in MasterChef are allocated to Strategy & Vault
        const lpBalanceOfVault = new BigNumber(_lpBalanceOfVault)
        const lpTokenRatioOfVault = new BigNumber(lpTotalSupply).isZero()
          ? new BigNumber(0)
          : new BigNumber(lpBalanceOfVault).div(new BigNumber(lpTotalSupply))

        // Total value of LP in MasterChef (include 2 Tokens)
        quoteTokenBalanceOfStrategy = new BigNumber(quoteTokenBalanceOfLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatioOfVault)
          .times(2)

        const lpTokenRatioOfMC = new BigNumber(lpTotalSupply).isZero()
          ? new BigNumber(0)
          : new BigNumber(lpBalanceOfMC).div(new BigNumber(lpTotalSupply))
        quoteTokenBalanceOfMC = new BigNumber(quoteTokenBalanceOfLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatioOfMC)
          .times(2)
      }

      let rewardTokenPriceVsQuote = new BigNumber(0)
      if ([QuoteToken.BNB.toString(), QuoteToken.WBNB.toString()].includes(vault.quoteTokenSymbolOfRewardLP)) {
        rewardTokenPriceVsQuote = new BigNumber(rewardLPBalanceOfQuoteToken)
          .div(new BigNumber(rewardLPBalanceOfToken))
          .times(wbnbPrice)
      } else {
        rewardTokenPriceVsQuote = new BigNumber(rewardLPBalanceOfQuoteToken).div(new BigNumber(rewardLPBalanceOfToken))
      }

      const [info, totalAllocPoint, rewardPerBlock, userInfo, _pendingReward] = await fetchMasterChefInfo(
        masterChefAddress,
        _masterChefABI,
        vault,
        strategyAddress
      )

      const [rewardBalanceOfStrategy] = await multicall(erc20, [
        {
          address: getAddress(vault.tokenAddressOfRewardLP),
          name: 'balanceOf',
          params: [strategyAddress],
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      const lpAmountOfStrategyInMC = new BigNumber(userInfo.amount._hex)
      const ratioOfAmountInMCVsVault = new BigNumber(lpAmountOfStrategyInMC).div(vaultTotalSupply)
      const pendingReward = new BigNumber(_pendingReward).plus(new BigNumber(rewardBalanceOfStrategy))

      return {
        ...vault,
        quoteTokenBalanceOfMC: quoteTokenBalanceOfMC.toJSON(),
        quoteTokenBalanceOfStrategy: quoteTokenBalanceOfStrategy.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        vaultTotalSupply: new BigNumber(vaultTotalSupply).toJSON(),
        ratioOfAmountInMCVsVault: ratioOfAmountInMCVsVault.toJSON(),
        vaultWithdrawFee: new BigNumber(withdrawalFee).div(withdrawalMax).toNumber(),
        earlyWithdrawFee: new BigNumber(earlyWithdrawalFee).div(withdrawalMax).toNumber(),
        lpAmountOfStrategyInMC: lpAmountOfStrategyInMC.toJSON(),
        rewardTokenPriceVsQuote: rewardTokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toJSON(),
        rewardPerBlock: new BigNumber(rewardPerBlock)
          .div(new BigNumber(10).pow(new BigNumber(rewardTokenDecimals)))
          .toJSON(),
        pendingReward: pendingReward.toJSON(),
      }
    })
  )
  return results
}

const fetchGeneralInfo = async (masterchef, vault, lpAddress) => {
  if (vault.masterchefABI === 'killswitch') {
    const [_byalan] = await multicall(kswIzludeABI, [{ address: getAddress(vault.kswIzlude), name: 'byalan' }])
    const [_lpBalanceOfMC] = await multicall(masterChefABI, [
      {
        address: getAddress(vault.finalMasterchefAddresses),
        name: 'userInfo',
        params: [vault.finalMasterchefpid, _byalan.toString()],
      },
    ])
    const lpBalanceOfMC = _lpBalanceOfMC.amount
    const quoteTokenBalanceOfLP = lpBalanceOfMC
    const [
      lpTotalSupply,
      quoteTokenDecimals,
      rewardLPBalanceOfQuoteToken,
      rewardLPBalanceOfToken,
      rewardTokenDecimals,
    ] = await multicall(erc20, [
      {
        address: lpAddress,
        name: 'totalSupply',
      },
      {
        address: getAddress(vault.quoteTokenAddresses),
        name: 'decimals',
      },
      {
        address: getAddress(vault.quoteTokenAddressOfRewardLP),
        name: 'balanceOf',
        params: [getAddress(vault.rewardTokenPriceLPAddresses)],
      },
      {
        address: getAddress(vault.tokenAddressOfRewardLP),
        name: 'balanceOf',
        params: [getAddress(vault.rewardTokenPriceLPAddresses)],
      },
      {
        address: getAddress(vault.tokenAddressOfRewardLP),
        name: 'decimals',
      },
    ])

    return [
      quoteTokenBalanceOfLP,
      lpBalanceOfMC,
      lpTotalSupply,
      quoteTokenDecimals,
      rewardLPBalanceOfQuoteToken,
      rewardLPBalanceOfToken,
      rewardTokenDecimals,
    ]
  }

  const [
    quoteTokenBalanceOfLP,
    lpBalanceOfMC,
    lpTotalSupply,
    quoteTokenDecimals,
    rewardLPBalanceOfQuoteToken,
    rewardLPBalanceOfToken,
    rewardTokenDecimals,
  ] = await multicall(erc20, [
    {
      address: getAddress(vault.quoteTokenAddresses),
      name: 'balanceOf',
      params: [lpAddress],
    },
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [masterchef],
    },
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    {
      address: getAddress(vault.quoteTokenAddresses),
      name: 'decimals',
    },
    {
      address: getAddress(vault.quoteTokenAddressOfRewardLP),
      name: 'balanceOf',
      params: [getAddress(vault.rewardTokenPriceLPAddresses)],
    },
    {
      address: getAddress(vault.tokenAddressOfRewardLP),
      name: 'balanceOf',
      params: [getAddress(vault.rewardTokenPriceLPAddresses)],
    },
    {
      address: getAddress(vault.tokenAddressOfRewardLP),
      name: 'decimals',
    },
  ])

  return [
    vault.isToken ? lpBalanceOfMC : quoteTokenBalanceOfLP,
    lpBalanceOfMC,
    lpTotalSupply,
    quoteTokenDecimals,
    rewardLPBalanceOfQuoteToken,
    rewardLPBalanceOfToken,
    rewardTokenDecimals,
  ]
}

const fetchMasterChefInfo = async (masterchef, abi, vault, strategy) => {
  if (vault.masterchefABI === 'killswitch') {
    const izlude = getAddress(vault.kswIzlude)
    const [_info, totalAllocPoint, kswPerSecond, _userInfo, _pendingReward] = await multicall(abi, [
      {
        address: masterchef,
        name: 'poolInfo',
        params: [izlude],
      },
      {
        address: masterchef,
        name: 'totalAllocPoint',
      },
      {
        address: masterchef,
        name: vault.rewardPerBlockField,
      },
      {
        address: masterchef,
        name: 'userInfo',
        params: [izlude, strategy],
      },
      {
        address: masterchef,
        name: vault.pendingRewardField,
        params: [izlude, strategy],
      },
    ])
    const info = {
      accKSWPerJellopy: _info.accKSWPerJellopy,
      allocPoint: _info.allocPoint,
      izlude: _info.izlude,
      lastRewardTime: _info.lastRewardTime,
      lpToken: _info.want,
    }

    const userInfo = { amount: _userInfo.jellopy, rewardDebt: _userInfo.rewardDebt }
    const rewardPerBlock = kswPerSecond * 3
    return [info, totalAllocPoint, rewardPerBlock, userInfo, _pendingReward]
  }

  const [info, totalAllocPoint, rewardPerBlock, userInfo, _pendingReward] = await multicall(abi, [
    {
      address: masterchef,
      name: 'poolInfo',
      params: [vault.pid],
    },
    {
      address: masterchef,
      name: 'totalAllocPoint',
    },
    {
      address: masterchef,
      name: vault.rewardPerBlockField,
    },
    {
      address: masterchef,
      name: 'userInfo',
      params: [vault.pid, strategy],
    },
    {
      address: masterchef,
      name: vault.pendingRewardField,
      params: [vault.pid, strategy],
    },
  ])

  return [info, totalAllocPoint, rewardPerBlock, userInfo, _pendingReward]
}

// const fetchStrategyInfo = async (masterChef, abi, vault, strategy) => {
//   if (vault.masterchefABI === 'killswitch') {
//     const [tmp] = await multicall(abi, [
//       {
//         address: masterChef,
//         name: 'userInfo',
//         params: [getAddress(vault.kswIzlude), strategy],
//       },
//     ])
//     return { amount: tmp.jellopy, rewardDebt: tmp.rewardDebt }
//   }

//   const [strategyInfo] = await multicall(abi, [
//     {
//       address: masterChef,
//       name: 'userInfo',
//       params: [vault.pid, strategy],
//     },
//   ])
//   return strategyInfo
// }

export const fetchRaffle = async () => {
  const raffleAddress = getVaultRaffleAddress()

  const [_wbnbBalance] = await multicall(erc20, [
    {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [raffleAddress],
    },
  ])

  const wbnbBalance = new BigNumber(_wbnbBalance)
  const [
    _latestWinner,
    _latestAwardAmount,
    _minVaultAmount,
    _minRaffleAmount,
    _minVaultCount,
    _initialDate,
    _latestDepositDate,
    _keys,
    _specialPot,
    _specialPotRatio,
  ] = await multicall(vaultRaffleABI, [
    {
      address: raffleAddress,
      name: 'latestWinner',
    },
    {
      address: raffleAddress,
      name: 'latestAwardAmount',
    },
    {
      address: raffleAddress,
      name: 'minVaultAmount',
    },
    {
      address: raffleAddress,
      name: 'minRaffleAmount',
    },
    {
      address: raffleAddress,
      name: 'minVaultCount',
    },
    {
      address: raffleAddress,
      name: 'initialDate',
    },
    {
      address: raffleAddress,
      name: 'latestDepositDate',
    },
    {
      address: raffleAddress,
      name: 'getKeys',
    },
    {
      address: raffleAddress,
      name: 'specialPot',
    },
    {
      address: raffleAddress,
      name: 'specialPotRatio',
    },
  ])

  const minVaultAmount = new BigNumber(_minVaultAmount)
  const minRaffleAmount = new BigNumber(_minRaffleAmount)
  const minVaultCount = new BigNumber(_minVaultCount).toNumber()
  const initialDate = new BigNumber(_initialDate).toNumber()
  const latestDepositDate = new BigNumber(_latestDepositDate).toNumber()
  const latestWinner = _latestWinner.toString()
  const latestAwardAmount = new BigNumber(_latestAwardAmount)
  const specialPot = _specialPot.toString()
  const specialPotRatio = new BigNumber(_specialPotRatio).div(1000).toNumber()

  let latestWinnerSymbol = ''
  if (latestWinner !== '0x0000000000000000000000000000000000000000') {
    const [_wantSymbol] = await multicall(vaultABI, [
      {
        address: latestWinner,
        name: 'symbol',
      },
    ])
    latestWinnerSymbol = _wantSymbol.toString()
  }
  let vaults = []
  if (_keys && _keys[0].length > 0) {
    const calls = _keys[0].map((key) => {
      return {
        address: raffleAddress,
        name: 'getEntryByKey',
        params: [key],
      }
    })

    const _vaults = await multicall(vaultRaffleABI, calls)
    vaults = _vaults.map((x) => {
      const balance = new BigNumber(x[0].balance._hex)
      return { vault: x[0].vault, balance: balance.toJSON() }
    })
  }

  return {
    raffleBalance: wbnbBalance.toJSON(),
    latestWinner,
    latestWinnerSymbol,
    latestWinnerAward: latestAwardAmount.toJSON(),
    minVaultAmount: minVaultAmount.toJSON(),
    minRaffleAmount: minRaffleAmount.toJSON(),
    minVaultCount,
    beginRound: initialDate,
    latestDepositDate,
    candidates: vaults,
    specialPot,
    specialPotRatio,
  }
}
