import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Box, Link } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'flex',
    padding: '8px 12px 8px 0px',
    '& img': {
      height: 48,
      display: 'initial',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      '& img': {
        height: 48,
      },
    },
    [theme.breakpoints.up('xl')]: {
      '& img': {
        height: 64,
      },
    },
  },
}))

const Logo = () => {
  const classes = useStyles()
  return (
    <Link href="/">
      <Box className={classes.logo}>
        <img src="/images/header_logo.png" alt="samoyed" />
      </Box>
    </Link>
  )
}
export default Logo
