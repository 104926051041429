import React from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close as CloseIcon } from '@mui/icons-material'
import connectors from './configs'

interface ConnectModalProps {
  open: boolean
  onClose: () => void
  onConnect: (string) => void
  error?: string
}

const ConnectModal: React.FC<ConnectModalProps> = ({ open, onClose, onConnect, error }) => {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  const handleConnect = (connectorId: string) => {
    onConnect(connectorId)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="connect-dialog" open={open}>
      <DialogTitle className={classes.title}>
        <Typography variant="h4">Connect Wallet</Typography>
        <IconButton aria-label="close" className={classes.btnClose} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.root}>
          {connectors.map((conn) => {
            return (
              <Grid key={conn.title} item xs={6}>
                <Box className={classes.btnConnect} onClick={() => handleConnect(conn.connectorId)}>
                  <img src={conn.icon} alt={conn.title} />
                  <Typography>{conn.title}</Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      {error && (
        <DialogActions>
          <Typography className={classes.errorMessage}>{error}</Typography>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ConnectModal

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minHeight: '25vh',
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#E8F4FF',
  },
  btnConnect: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    cursor: 'pointer',
    color: 'rgb(40, 13, 95)',
    '&:hover': { opacity: '0.7' },
  },
  btnClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  errorMessage: {
    color: 'red',
  },
}))
