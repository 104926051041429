import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10,512,000
export const SMOY_PER_BLOCK = new BigNumber(0.8)
export const SMOY_PER_YEAR = SMOY_PER_BLOCK.times(BLOCKS_PER_YEAR) // 31,536,000
export const BASE_EXCHANGE_URL = 'https://exchange.foodcourt.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const DEPOSIT_FEE_MINIMUM_SMOY = 200 // mininum of Smoy for deposit fee
export const LOCKED_MARKETING_SMOY = Number(process.env.REACT_APP_LOCKED_MARKETING_SMOY) // Locked IFO before launch date
export const LOCKED_Q4REWARD_SMOY = 3_600_000 + 900_000 + 200_000 // Locked Reward for Q4
export const LOCKED_DEV_SMOY = 10_000_000 // Locked Dev's Smoy for 180 days
export const TOTAL_SMOY_BURNED = 63589.488 + 61500.057 // Total SMOY Burned
export const NFT_API_URL = 'https://api.samoyedfinance.app'
export const VAULT_WITHDRAW_LOCK = 10 * 24 * 60 * 60 // 10 days
