import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import multicall from 'utils/multicall'
import { MintPuppyGame, MiniGameUserData } from 'state/types'
import mintPuppyGameABI from 'config/abi/mintPuppyGame.json'
import erc20ABI from 'config/abi/erc20.json'

import { getMintPuppyGameAddress, getSmoyAddress, getBusdAddress } from 'utils/addressHelpers'

const initialState: MintPuppyGame = {
  busdCost: 0,
  smoyCost: 0,
  userData: {},
}

export const mintPuppyGameSlice = createSlice({
  name: 'MintPuppyGameReducer',
  initialState,
  reducers: {
    setMintPuppyGamePublicInfo: (state, action) => {
      const data: MintPuppyGame = action.payload
      return { ...state, ...data }
    },
    setMiniGameUserData: (state, action) => {
      const data: MiniGameUserData = action.payload
      // console.log('payloadUserData: ', data)
      return { ...state, userData: { ...data } }
    },
  },
})

// Actions
export const { setMintPuppyGamePublicInfo, setMiniGameUserData } = mintPuppyGameSlice.actions

export const fetchMintPuppyGamePublicInfo = () => async (dispatch) => {
  const mintPuppyGameAddress = getMintPuppyGameAddress()

  let busdCost = 99999999.0
  let smoyCost = 99999999.0
  // console.log('Invoke fetchMintPuppyGamePublicInfo')
  try {
    const [_busdCost, _smoyCost] = await multicall(mintPuppyGameABI, [
      { address: mintPuppyGameAddress, name: 'busdCost', params: [] },
      { address: mintPuppyGameAddress, name: 'smoyCost', params: [] },
    ])
    busdCost = parseFloat(ethers.utils.formatEther(_busdCost[0]))
    smoyCost = parseFloat(ethers.utils.formatEther(_smoyCost[0]))
  } catch (err) {
    console.error(err)
  }
  // console.log(busdCost, smoyCost)
  dispatch(
    setMintPuppyGamePublicInfo({
      busdCost,
      smoyCost,
    })
  )
}

export const fetchUserData = (account) => async (dispatch) => {
  if (!account) return

  const miniGameAddress = getMintPuppyGameAddress()
  const smoyAddress = getSmoyAddress()
  const busdAddress = getBusdAddress()
  const calls = [
    { address: smoyAddress, name: 'balanceOf', params: [account] },
    { address: smoyAddress, name: 'allowance', params: [account, miniGameAddress] },
    { address: busdAddress, name: 'balanceOf', params: [account] },
    { address: busdAddress, name: 'allowance', params: [account, miniGameAddress] },
  ]

  const [_smoyBalanceOf, _smoyAllowance, _busdBalanceOf, _busdAllowance] = await multicall(erc20ABI, calls)
  // console.log('invoke fetch userData')
  // console.log(calls)
  // console.log('_smoyBalanceOf: ', ethers.utils.formatEther(_smoyBalanceOf[0]))
  // console.log('_smoyAllowance: ', ethers.utils.formatEther(_smoyAllowance[0]))
  // console.log('_busdBalanceOf: ', ethers.utils.formatEther(_busdBalanceOf[0]))
  // console.log('_busdAllowance: ', ethers.utils.formatEther(_busdAllowance[0]))

  const payload = {
    smoyBalance: new BigNumber(_smoyBalanceOf[0]._hex).toJSON(),
    smoyAllowance: new BigNumber(_smoyAllowance[0]._hex).toJSON(),
    busdBalance: new BigNumber(_busdBalanceOf[0]._hex).toJSON(),
    busdAllowance: new BigNumber(_busdAllowance[0]._hex).toJSON(),
  }

  dispatch(setMiniGameUserData(payload))
}

export default mintPuppyGameSlice.reducer
