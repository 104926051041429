import React from 'react'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import theme from '../style/Theme'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const ThemeContextProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeContextProvider
