/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import vaultsConfig from 'config/constants/vaults'
import {
  getBusdAddress,
  getSmoyAddress,
  getWbnbAddress,
  getUsdtAddress,
  getGalaAddress,
  getAvaxAddress,
  getMaticAddress,
} from 'utils/addressHelpers'
import { fetchVaults, fetchRaffle } from './fetchVaults'
import {
  fetchUserLPAllowances,
  fetchUserLPTokenBalances,
  fetchUserLPStakedBalances,
  fetchUserTokenAllowancesToHelper,
  fetchUserTokenBalances,
  fetchUserVaultLPAllownaces,
  fetchUserBNBBalance,
  fetchUserDepositTime,
} from './fetchVaultsUser'
import { VaultsState, Vault, RaffleState } from '../types'

const initialState: VaultsState = { data: [...vaultsConfig] }

export const vaultsSlice = createSlice({
  name: 'Vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const liveVaultsData: Vault[] = action.payload.vaults
      const raffleData: RaffleState = action.payload.raffle

      state.data = state.data.map((vault) => {
        const liveVaultData = liveVaultsData.find((f) => f.pid === vault.pid)
        return { ...vault, ...liveVaultData }
      })
      state.raffle = raffleData
    },
    setVaultUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
  },
})

// Actions
export const { setVaultsPublicData, setVaultUserData } = vaultsSlice.actions

// Thunks
export const fetchVaultsPublicDataAsync = () => async (dispatch) => {
  const vaults = await fetchVaults()
  const raffle = await fetchRaffle()

  dispatch(setVaultsPublicData({ vaults, raffle }))
}

export const fetchVaultUserDataAsync = (account) => async (dispatch) => {
  const lpAllowances = await fetchUserLPAllowances(account)
  const lpBalances = await fetchUserLPTokenBalances(account)
  const lpStakedBalances = await fetchUserLPStakedBalances(account)
  const vaultAllowances = await fetchUserVaultLPAllownaces(account)

  const wbnbAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getWbnbAddress())
  const wbnbBalances = await fetchUserTokenBalances(account, getWbnbAddress())
  const busdAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getBusdAddress())
  const busdBalances = await fetchUserTokenBalances(account, getBusdAddress())
  const usdtAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getUsdtAddress())
  const usdtBalances = await fetchUserTokenBalances(account, getUsdtAddress())
  const smoyAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getSmoyAddress())
  const smoyBalances = await fetchUserTokenBalances(account, getSmoyAddress())

  const galaAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getGalaAddress())
  const galaBalances = await fetchUserTokenBalances(account, getGalaAddress())
  const maticAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getMaticAddress())
  const maticBalances = await fetchUserTokenBalances(account, getMaticAddress())
  const avaxAllowancesToHelper = await fetchUserTokenAllowancesToHelper(account, getAvaxAddress())
  const avaxBalances = await fetchUserTokenBalances(account, getAvaxAddress())

  const bnbBalance = await fetchUserBNBBalance(account)
  const depositTimes = await fetchUserDepositTime(account)

  const arrayOfUserDataObjects = lpAllowances.map((allowance, index) => {
    return {
      index,
      lpAllowance: lpAllowances[index],
      lpBalance: lpBalances[index],
      lpStakedBalance: lpStakedBalances[index],
      vaultAllowance: vaultAllowances[index],
      wbnbHelperAllowance: wbnbAllowancesToHelper[index],
      wbnbBalance: wbnbBalances[index],
      busdHelperAllowance: busdAllowancesToHelper[index],
      busdBalance: busdBalances[index],
      usdtHelperAllowance: usdtAllowancesToHelper[index],
      usdtBalance: usdtBalances[index],
      smoyHelperAllowance: smoyAllowancesToHelper[index],
      smoyBalance: smoyBalances[index],

      galaHelperAllowance: galaAllowancesToHelper[index],
      galaBalance: galaBalances[index],
      maticHelperAllowance: maticAllowancesToHelper[index],
      maticBalance: maticBalances[index],
      avaxHelperAllowance: avaxAllowancesToHelper[index],
      avaxBalance: avaxBalances[index],

      depositTime: depositTimes[index],
      bnbBalance,
    }
  })

  dispatch(setVaultUserData({ arrayOfUserDataObjects }))
}

export default vaultsSlice.reducer
