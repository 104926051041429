import React, { useState, useMemo } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ConnectModal from './WalletModal/ConnectModal'
import { ConnectorId, localStorageKey } from './WalletModal/configs'
import PawButtton from './Button/PawButtton'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1),
    minWidth: '120px',
  },
}))

const UnlockButton = (props) => {
  const wallet = useWallet()
  const classes = useStyles()
  const { usePawButton, ...other } = props
  const [open, setOpen] = useState(false)

  const handleButtonClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConnect = (connectorId: ConnectorId) => {
    wallet.connect(connectorId)
    if (wallet.status !== 'error') {
      window.localStorage.setItem(localStorageKey, '1')
      setOpen(false)
    }
  }

  const error: string = useMemo(() => {
    if (wallet.status === 'error' && wallet.error && wallet.error.toString().includes('ChainUnsupportedError')) {
      return 'Chain Unsupported'
    }
    return undefined
  }, [wallet.status, wallet.error])

  return (
    <>
      {usePawButton ?
        <PawButtton onClick={handleButtonClick} {...other} color="skyBlue">UNLOCK WALLET</PawButtton>
      :
        <Button variant="contained" color="secondary" className={classes.button} onClick={handleButtonClick} {...other}>
          <span style={{ whiteSpace: 'nowrap' }}>UNLOCK WALLET</span>
        </Button>
      }
      <ConnectModal open={open} onClose={handleClose} onConnect={handleConnect} error={error} />
    </>
  )
}

export default UnlockButton
