/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import puppyStorageABI from 'config/abi/puppyStorage.json'
import { getPuppyStorageAddress } from 'utils/addressHelpers'
import { toNumberHex } from 'utils/nftUtils'
import { NFTState, NFTInfo, NftTypeEnum } from '../types'

const initialState: NFTState = { data: [], loading: false }

export const puppySlice = createSlice({
  name: 'PuppyReducer',
  initialState,
  reducers: {
    setUserPuppy: (state, action) => {
      const data: NFTInfo[] = action.payload
      const newIds = data.map((i) => {
        return i.tokenId
      })

      const existIds = state.data.map((i) => {
        return i.tokenId
      })

      // Prevent unnecessary re-render
      if (
        !newIds.every((id) => {
          return existIds.includes(id)
        })
      ) {
        return { ...state, data: [...data], loading: false }
      }
      return state
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload }
    },
  },
})

// Actions
export const { setUserPuppy, setLoading } = puppySlice.actions

// Thunks
export const fetchUserPuppys = (account) => async (dispatch) => {
  dispatch(setLoading(true))
  const balance = await fetchUserBalance(account)

  if (balance > 0) {
    const ids = await fetchUserTokenIndex(account, balance)
    const items = await fetchTokenInfomation(ids)
    const _items = items.map((item) => {
      return {
        ...item,
        nftType: NftTypeEnum.Puppy,
        imagePath: 'puppy',
        tokenLabel: toNumberHex(Number(item.tokenId), 2),
      }
    })
    dispatch(setUserPuppy(_items))
  } else {
    dispatch(setUserPuppy([]))
  }
  dispatch(setLoading(false))
}

const fetchUserBalance = async (account) => {
  const calls = [
    {
      address: getPuppyStorageAddress(),
      name: 'balanceOf',
      params: [account],
    },
  ]

  const [res] = await multicall(puppyStorageABI, calls)

  return new BigNumber(res[0]._hex).toNumber()
}

const fetchUserTokenIndex = async (account, count) => {
  const calls = []
  for (let i = 0; i < count; i++) {
    calls.push({
      address: getPuppyStorageAddress(),
      name: 'tokenOfOwnerByIndex',
      params: [account, i],
    })
  }

  const results = await multicall(puppyStorageABI, calls)
  return results.map((i) => {
    return new BigNumber(i[0]._hex).toNumber()
  })
}

const fetchTokenInfomation = async (ids) => {
  const calls = ids.map((id) => {
    return {
      address: getPuppyStorageAddress(),
      name: 'getTokenInformation',
      params: [id],
    }
  })
  const results = await multicall(puppyStorageABI, calls)
  const parsedResults = results.map((i) => {
    return {
      pack: new BigNumber(i._pack._hex).toNumber(),
      seasonNumber: new BigNumber(i._seasonNumber._hex).toNumber(),
      tokenId: new BigNumber(i._ticketNumber._hex).toString(),
      ticketType: new BigNumber(i._ticketType._hex).toNumber(),
    }
  })
  return parsedResults
}

// const fetchTokenUriFromTokenIds = async (ids) => {
//   const calls = ids.map((id) => {
//     return {
//       address: getPuppyStorageAddress(),
//       name: 'tokenURI',
//       params: [id],
//     }
//   })
//   const results = await multicall(puppyStorageABI, calls)
//   return results.map((i) => {
//     return JSON.parse(i[0].toString())
//   })
// }

export default puppySlice.reducer
