import React, { lazy, Suspense, useEffect } from 'react'
import { CssBaseline, Container } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Route, Router, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import NotificationBar from 'components/Header/NotificationBar'
import BGThreeSamoy from './components/BG/BGThreeSamoy'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import PageLoader from './components/PageLoader'
import { localStorageKey } from './components/WalletModal/configs'
import history from './routerHistory'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Pools = lazy(() => import('./views/Pools'))
const Farms = lazy(() => import('./views/Farms'))
const Vaults = lazy(() => import('./views/Vaults'))
const Swap = lazy(() => import('./views/Swap'))
const Inventory = lazy(() => import('./views/Inventory'))
const MiniGame = lazy(() => import('./views/Game/MiniGame'))
const Prizes = lazy(() => import('./views/Prizes/Prizes'))
const RoundHistory = lazy(() => import('./views/Game/RoundHistory'))
const MyMarketPage = lazy(() => import('./views/Marketplace/MyPage'))
const Marketplace = lazy(() => import('./views/Marketplace/Nasdaq'))
const ListingInfo = lazy(() => import('./views/Marketplace/ListingInfo'))
const MarketplaceHistory = lazy(() => import('./views/Marketplace/History'))
const NotFound = lazy(() => import('./views/NotFound'))
const Maintenance = lazy(() => import('./views/System/Maintenance'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const useStyles = makeStyles((theme) => ({
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '80px 0 0',
  },
  main: {
    padding: theme.spacing(0),
    background: '#fff',
    minHeight: 'calc(100vh - 245px)',
    position: 'relative',
    zIndex: 11,
  },
  mainRelative: {
    padding: theme.spacing(0),
    background: '#fff',
    minHeight: 'calc(100vh - 245px)',
  },
  mainOrangePaw: {
    backgroundSize: '100% auto',
    backgroundColor: '#FFDFBA',
    backgroundImage: 'url(/images/BG/BG_Footprint.png)',
  },
  decorationOne: {
    backgroundImage: 'url(/images/BG/decorate-bar.png)',
    width: '100%',
    height: '108px',
    backgroundSize: '100% auto',
    position: 'absolute',
    top: 80,
    left: 0,
    zIndex: 100,
    backgroundRepeat: 'no-repeat',
  },
  decorationTwo: {
    backgroundImage: 'url(/images/BG/decorate-snow.png)',
    width: '100%',
    height: '560px',
    backgroundSize: '100% auto',
    position: 'absolute',
    top: 80,
    left: 0,
    backgroundRepeat: 'no-repeat',
    zIndex: 10,
  },
}))

const App: React.FC = () => {
  const { account, connect } = useWallet()
  const classes = useStyles()

  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null

    /*
    const footerObj = document.getElementById('footerContainer')
    const BGThreeSamoyObj = document.getElementById('BGThreeSamoy')

    setTimeout(function () {
      if (footerObj !== null && BGThreeSamoyObj !== null) {
        const rect = document.getElementById('footerContainer').getBoundingClientRect()
        const diff = window.innerHeight - rect.top

        if (diff > 0) {
          BGThreeSamoyObj.style.bottom = `${diff}px`
        } else {
          BGThreeSamoyObj.style.bottom = '0px'
        }

        BGThreeSamoyObj.style.bottom = '0px'
        BGThreeSamoyObj.style.opacity = '1'
      }
    }, 2000)

    function moveBG() {
      if (footerObj !== null && BGThreeSamoyObj !== null) {
        const rect = document.getElementById('footerContainer').getBoundingClientRect()
        const diff = window.innerHeight - rect.top - 5000000

        if (diff > 0) {
          BGThreeSamoyObj.style.bottom = `${diff}px`
        } else {
          BGThreeSamoyObj.style.bottom = '0px'
        }
      }

      setTimeout(moveBG, 1)
    }
    moveBG()
    */
  }, [])

  useEffect(() => {
    if (!account && window.localStorage.getItem(localStorageKey)) {
      connect('injected')
    }
    /*
    if (window.onscroll === null) {
      window.onscroll = (e) => {
        const footerObj = document.getElementById('footerContainer')
        const BGThreeSamoyObj = document.getElementById('BGThreeSamoy')

        if (footerObj !== null && BGThreeSamoyObj !== null) {        
          const rect = document.getElementById('footerContainer').getBoundingClientRect()
          const diff = window.innerHeight - rect.top

          if (diff > 0) {
            BGThreeSamoyObj.style.bottom = `${diff}px`
          } else {
            BGThreeSamoyObj.style.bottom = '0px'
          }

        }
      }
    }
    */
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router history={history}>
      {process.env.REACT_APP_MAINTENANCE_MODE === 'Y' && (
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Container className={classes.main} maxWidth="lg">
                <Maintenance />
              </Container>
            </Route>
          </Switch>
        </Suspense>
      )}
      {process.env.REACT_APP_MAINTENANCE_MODE !== 'Y' && (
        <div>
          <CssBaseline />
          <Header />

          <div className={classes.app}>
            <BGThreeSamoy />
            <NotificationBar />
            <Suspense fallback={<PageLoader />}>
              <Switch>
                <Route path="/" exact>
                  <Container className={classes.mainRelative} maxWidth="lg">
                    <Home />
                    <Footer />
                  </Container>
                </Route>
                <Route path="/pool">
                  <Container className={classes.main} maxWidth="lg">
                    <Pools />
                  </Container>
                </Route>
                <Route path="/farm">
                  <Container className={classes.main} maxWidth="lg">
                    <Farms />
                  </Container>
                </Route>
                <Route path="/samoypot">
                  <Container className={classes.mainRelative} maxWidth="lg">
                    <Vaults />
                    <Footer />
                  </Container>
                </Route>
                <Route path="/swap">
                  <Container className={classes.main} maxWidth="lg">
                    <Swap />
                  </Container>
                </Route>
                <Route path="/inventory">
                  <Container className={classes.main} maxWidth="lg">
                    <Inventory />
                  </Container>
                </Route>
                <Route path="/minigame/history">
                  <Container className={classes.main} maxWidth="lg">
                    <RoundHistory />
                  </Container>
                </Route>
                <Route path="/minigame">
                  <Container className={classes.main} maxWidth="lg">
                    <MiniGame />
                  </Container>
                </Route>
                <Route path="/prizes">
                  <Container className={classes.main} maxWidth="lg">
                    <Prizes />
                  </Container>
                </Route>
                <Route path="/marketplace/my">
                  <Container className={classes.main} maxWidth="lg">
                    <MyMarketPage />
                  </Container>
                </Route>
                <Route path="/marketplace/history">
                  <Container className={classes.main} maxWidth="lg">
                    <MarketplaceHistory />
                  </Container>
                </Route>
                <Route path="/marketplace/listing/:tokenId">
                  <Container className={classes.main} maxWidth="lg">
                    <ListingInfo />
                  </Container>
                </Route>
                <Route path="/marketplace">
                  <Container className={classes.main} maxWidth="lg">
                    <Marketplace />
                  </Container>
                </Route>
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </div>
        </div>
      )}
    </Router>
  )
}

export default React.memo(App)
