import { createTheme, adaptV4Theme } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    havest: Palette['primary']
  }
  interface PaletteOptions {
    havest: PaletteOptions['primary']
  }
}

const Theme = createTheme(
  adaptV4Theme({
    overrides: {
      // override API
      // https://material-ui.com/api/{component}/

      MuiButton: {
        root: {
          color: '#2c2c2c',
          textTransform: 'none',
        },
      },
      /*
    MuiLink: {
      root: {
        color: '#ffffff',
      },
    },
    MuiPaper: {
      root: {
        color: '#ffffff',
        backgroundColor: '#260e40',
      },
    },
    */
      MuiCssBaseline: {
        '@global': {
          '.expandIcon': {
            color: '#ed84ad',
          },
          '.MuiAccordion-root.Mui-disabled': {
            backgroundColor: '#fff !important',
          },
          '.MuiAccordionSummary-root .collapseText': {
            display: 'none',
            fontSize: '16px',
            color: '#ed84ad',
          },
          '.MuiAccordionSummary-root .expandText': {
            display: 'none',
            transform: 'rotate(180deg)',
            fontSize: '16px',
            color: '#ed84ad',
          },
          '.MuiAccordionSummary-root .Mui-expanded .collapseText': {
            display: 'none',
          },
          '.MuiAccordionSummary-root .Mui-expanded .expandText': {
            display: 'none',
          },
          '@media (min-width:600px)': {
            '.MuiAccordionSummary-root .collapseText': {
              display: 'block',
            },
            '.MuiAccordionSummary-root .Mui-expanded .expandText': {
              display: 'block',
            },
          },
        },
      },
      MuiContainer: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiTooltip: {
        popper: {},
        arrow: {
          color: 'rgb(42, 188, 212)',
        },
        tooltip: {
          fontSize: '0.9em',
          backgroundColor: 'rgb(42, 188, 212)',
          color: '#fff',
          padding: '16px',
        },
      },
    },

    mixins: {
      toolbar: {
        minHeight: 80,
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 80,
        },
        '@media (min-width:600px)': {
          minHeight: 80,
        },
      },
    },
    typography: {
      fontFamily: '"Kanit", "Helvetica", "Arial", sans-serif',

      fontSize: 13,
      htmlFontSize: 15,
      h1: {
        fontSize: '2.5rem',
        lineHeight: '1.2',
      },
      h2: {
        fontSize: '2rem',
        lineHeight: '1.2',
      },
      h3: {
        fontSize: '1.75rem',
        lineHeight: '1.2',
      },
      h4: {
        fontSize: '1.5rem',
        lineHeight: '1.2',
      },
      h5: {
        fontSize: '1.25rem',
        lineHeight: '1.2',
      },
      h6: {
        fontSize: '1rem',
        lineHeight: '1.2',
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 500,
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '0.8rem',
      },
    },
    palette: {
      primary: {
        // main: '#fff6e8'
        main: '#FFFFB5',
        dark: '#ffff82',
      },
      secondary: {
        main: '#ABDEE6',
        dark: 'rgb(42, 188, 212)',
      },
      text: {
        primary: '#2c2c2c',
      },
      background: {
        paper: '#fff',
        default: '#f5f5f5',
      },
      /*
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    background: {
      paper: '#260e40',
      default: '#ffffff',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
    },
    */
      havest: { main: 'red', dark: 'green' },
    },
  })
)

export default Theme
