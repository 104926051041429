import contracts from './contracts'
import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: QuoteToken.SMOY,
    stakingTokenName: QuoteToken.SMOY,
    stakingTokenAddress: contracts.smoy[process.env.REACT_APP_CHAIN_ID],
    contractAddress: contracts.samoyedMasterChef,
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://samoyedfinance.app',
    harvest: true,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
]

export default pools
