import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Link, Box, Container } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    fontFamily: "'Dosis', sans-serif",
    position: 'relative',
    zIndex: 11,
    backgroundColor: 'rgba(43, 28, 47, 0.33)',
    marginTop: theme.spacing(0),
    padding: theme.spacing(2, 1, 2),
    overflow: 'hidden',
  },
  footerCover: {
    display: 'flex',
    flexDirection: 'row',
  },
  footerIcon: {
    display: 'flex',
    '& img': {
      height: 36,
      marginRight: theme.spacing(1),
    },
  },
  footerPartner: {
    display: 'none',
  },
  footerContact: {
    display: 'block',
    textAlign: 'center',
    fontSize: '20px',
    margin: '0 0 0 auto',
    '& a': {
      display: 'inline-block',
      margin: '0 4px',
      color: theme.palette.text.primary,
    },
    '& svg': {
      fontSize: '28px',
    },
    '& span': {
      marginBottom: '4px',
      display: 'block',
    },
  },
  circleLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    '& img': {
      maxWidth: '36px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    footerContainer: {
      padding: theme.spacing(2, 2, 4),
    },
    footerCover: {
      flexDirection: 'row',
    },
    footerIcon: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        height: 48,
      },
    },
    circleLogo: {
      width: '45px',
      '& img': {
        maxWidth: '45px',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    footerPartner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      '& .inspex': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .chainlink': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& h5': {
        color: '#2B1C2F',
        margin: '0 8px 0 0',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 800,
      },
      '& img': {
        width: 'auto',
        height: '36px',
      },
    },
    circleLogo: {
      width: '36px',
      '& img': {
        maxWidth: '36px',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    footerPartner: {
      '& img': {
        height: '64px',
      },
    },
    circleLogo: {
      width: '45px',
      '& img': {
        maxWidth: '45px',
      },
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <Box id="footerContainer" className={classes.footerContainer}>
      <Container className={classes.footerCover} disableGutters maxWidth="lg">
        <Box className={classes.footerIcon}>
          <img src="/images/header_logo.png" alt="logo" />
        </Box>
        <Box className={classes.footerPartner}>
          <Box className="inspex">
            <h5>Audited by</h5>
            <Link href="https://link.inspex.co/library-samoyedfinance-token-masterchef" target="_blank">
              <img src="/images/logo/inspex.png" alt="" />
            </Link>
          </Box>
          <Box className="chainlink">
            <h5>Oracle Prizes by</h5>
            <img src="/images/logo/badge_chainlink.png" alt="" />
          </Box>
        </Box>
        <Box className={classes.footerContact}>
          <Link target="_blank" href="https://docs.samoyedfinance.app">
            <div className={classes.circleLogo}>
              <img src="/images/icons/icon-gitbook.svg" alt="gitbook" />
            </div>
          </Link>
          <Link target="_blank" href="https://twitter.com/samoyedfinance">
            <div className={classes.circleLogo}>
              <img src="/images/icons/icon-twitter.svg" alt="twitter" />
            </div>
          </Link>
          <Link target="_blank" href="https://medium.samoyedfinance.app">
            <div className={classes.circleLogo}>
              <img src="/images/icons/icon-medium.svg" alt="medium" />
            </div>
          </Link>
          <Link target="_blank" href="https://t.me/samoyedfinance">
            <div className={classes.circleLogo}>
              <img src="/images/icons/icon-telegram.svg" alt="telegram" />
            </div>
          </Link>
          <Link target="_blank" href="https://github.com/Samoyed-DeFi">
            <div className={classes.circleLogo}>
              <img src="/images/icons/icon-github.svg" alt="github" />
            </div>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
