import addresses from 'config/constants/contracts'
import vaultContracts from 'config/constants/vaultContracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const mainNetChainId = 56
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

// Main =====================================================
export const getPancakeMasterChefAddress = () => {
  return getAddress(addresses.pancakeMasterChef)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.samoyedMasterChef)
}

// Token =====================================================
export const getSmoyAddress = () => {
  return getAddress(addresses.smoy)
}

export const getWbnbAddress = () => {
  return getAddress(addresses.wbnb)
}

export const getBusdAddress = () => {
  return getAddress(addresses.busd)
}

export const getUsdtAddress = () => {
  return getAddress(addresses.usdt)
}

export const getKMaticAddress = () => {
  return getAddress(addresses.kMatic)
}

export const getCouponAddress = () => {
  return getAddress(addresses.coupon)
}

export const getMaticAddress = () => {
  return getAddress(vaultContracts.maticToken)
}

export const getGalaAddress = () => {
  return getAddress(vaultContracts.galaToken)
}

export const getAvaxAddress = () => {
  return getAddress(vaultContracts.avaxToken)
}

// LP =====================================================
export const getSmoyBusdLPAddress = () => {
  return getAddress(addresses.smoyBusdLP)
}
export const getSmoyBnbLPAddress = () => {
  return getAddress(addresses.smoyBnbLP)
}
export const getBnbBusdLPAddress = () => {
  return getAddress(addresses.bnbBusdLP)
}
export const getUsdtBusdLPAddress = () => {
  return getAddress(addresses.usdtBusdLP)
}
export const getKMaticBnbLPAddress = () => {
  return getAddress(addresses.kMaticBnbLP)
}
export const getPancakeBnbBusdLPAddress = () => {
  return getAddress(addresses.pancakeBnbBusdLP)
}

// Vault =====================================================
export const getVaultRaffleAddress = () => {
  return getAddress(vaultContracts.raffle)
}
export const getVaultHelperAddress = () => {
  return getAddress(vaultContracts.smoyVaultHelper)
}
export const getSmoyBusdVaultAddress = () => {
  return getAddress(vaultContracts.SMOYxSmoyBusdVault)
}
export const getSmoyBnbVaultAddress = () => {
  return getAddress(vaultContracts.SMOYxSmoyBnbVault)
}
export const getBnbBusdVaultAddress = () => {
  return getAddress(vaultContracts.SMOYxBnbBusdVault)
}

// Other =====================================================
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}
export const getGodfatherStorageAddress = () => {
  return getAddress(addresses.godfatherStorage)
}
export const getPuppyStorageAddress = () => {
  return getAddress(addresses.puppyStorage)
}
export const getMiniGameAddress = () => {
  return getAddress(addresses.miniGame)
}

export const getMarketplaceAddress = () => {
  return getAddress(addresses.marketplace)
}

export const getAirDropUtilAddress = () => {
  return getAddress(addresses.airDropUtil)
}

export const getLottoAwardAddress = () => {
  return getAddress(addresses.lottoAward)
}

export const getLottoCheckGodfatherAddress = () => {
  return getAddress(addresses.lottoCheckGodfather)
}

export const getLottoCheckPuppyAddress = () => {
  return getAddress(addresses.lottoCheckPuppy)
}

export const getLottoCheckMoyAddress = () => {
  return getAddress(addresses.lottoCheckMoy)
}

export const getMintPuppyGameAddress = () => {
  return getAddress(addresses.mintPuppyGame)
}
