import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const useStyles = makeStyles((theme) => ({
  listMenu: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
    '& .fas': {
      fontSize: '14px',
    },
    '& .fas, svg': {
      color: 'rgba(43, 28, 47, 1) !important',
    },
    '& .listText': {
      '& span': {
        color: 'rgba(43, 28, 47, 1) !important',
        fontFamily: "'Dosis', sans-serif !important",
        fontWeight: 800,
      },
    },
  },
  earnMenu: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      '& li': {
        color: 'rgba(43, 28, 47, 1)',
        fontSize: '20px',
        fontFamily: "'Dosis', sans-serif",
        fontWeight: 800,
        lineHeight: '18px',
        padding: '4px 8px 4px 40px',

        '& a, a:visited, a:hover': {
          color: 'rgba(43, 28, 47, 1)',
          border: '2px solid #FF8FC1',
          boxShadow: 'inset 0px 1px 0px 2px rgb(255 143 193 / 33%)',
          textDecoration: 'none',
          backgroundColor: '#fff',
          padding: '4px 8px',
          borderRadius: '8px',
          minWidth: '64px',
        },
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    earnMenu: {
      '& .MuiPaper-root': {
        backgroundColor: 'rgba(43, 28, 47, 0.33)',
      },
    },
  },
}))

const MenuList = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <List className={classes.listMenu}>
        <ListItem
          id="earn-button"
          aria-controls="earn-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disableGutters
          className="listButton first"
          button
          component="a"
          href="#"
          onClick={handleClick}
          style={{ minWidth: open ? '111px' : 'auto' }}
        >
          <ListItemIcon className="listIcon">
            <img src="/images/icons/earn.svg" alt="earn" />
          </ListItemIcon>
          <ListItemText className="listText" primary="Earn" />
        </ListItem>

        <ListItem disableGutters className="listButton" button component="a" href="/marketplace">
          <ListItemIcon className="listIcon">
            <img src="/images/icons/market.svg" alt="market" />
          </ListItemIcon>
          <ListItemText className="listText" primary="Marketplace" />
        </ListItem>

        <ListItem disableGutters className="listButton" button component="a" href="/minigame">
          <ListItemIcon className="listIcon">
            <img src="/images/icons/game.svg" alt="game" />
          </ListItemIcon>
          <ListItemText className="listText" primary="Game" />
        </ListItem>

        <ListItem disableGutters className="listButton" button component="a" href="/prizes">
          <ListItemIcon className="listIcon">
            <img src="/images/icons/prizes.svg" alt="prizes" />
          </ListItemIcon>
          <ListItemText className="listText" primary="Prizes" />
        </ListItem>

        <ListItem disableGutters className="listButton" button component="a" href="/marketplace/my">
          <ListItemIcon className="listIcon">
            <img src="/images/icons/inventory.svg" alt="inventory" />
          </ListItemIcon>
          <ListItemText className="listText" primary="Inventory" />
        </ListItem>

        <ListItem
          disableGutters
          className="listButton"
          button
          component="a"
          target="_blank"
          href="https://docs.samoyedfinance.app/"
        >
          <ListItemIcon className="listIcon">
            <img src="/images/icons/doc.svg" alt="inventory" />
          </ListItemIcon>
          <ListItemText className="listText" primary="Docs" />
        </ListItem>
      </List>

      <Menu
        id="earn-menu"
        className={classes.earnMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'earn-button',
        }}
      >
        <MenuItem>
          <a href="/samoypot">Pot</a>
        </MenuItem>
        <MenuItem>
          <a href="/farm">Farm</a>
        </MenuItem>
        <MenuItem>
          <a href="/pool">Pool</a>
        </MenuItem>
      </Menu>
    </>
  )
}

export default MenuList
