import React from 'react'
import withStyles from '@mui/styles/withStyles';
import CssBaseline from '@mui/material/CssBaseline'

// const colors = {
//   white: '#ffffff',
//   primary: '#5578eb',
//   info: '#5578eb',
//   success: '#0abb87',
//   danger: '#fd397a',
//   warning: '#ffb822',
// }

const GlobalStyle = withStyles({
  '@global': {
    // body: {
    //   background: 'radial-gradient(#550d39, #00103e)',
    //   fontFamily: 'Montserrat, sans-serif',
    //   '-webkit-font-smoothing': 'antialiased',
    //   '-moz-osx-font-smoothing': 'grayscale',
    //   color: colors.white,
    //   fontWeight: 400,
    //   '&:before': {
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     backgroundSize: '140%',
    //     height: '100vh',
    //     position: 'fixed; top: 0; left: 0; width: 100vw',
    //   },
    // },
  },
})(() => null)

const GlobalCss = () => {
  return (
    <>
      <CssBaseline />
      <GlobalStyle />
    </>
  )
}

export default GlobalCss
