import BigNumber from 'bignumber.js'
import { FarmConfig, PoolConfig, VaultConfig } from 'config/constants/types'

export type TranslatableText =
  | string
  | {
      id: number
      fallback: string
      data?: {
        [key: string]: string | number
      }
    }

export interface Farm extends FarmConfig {
  tokenAmount?: BigNumber
  tokenBalance?: BigNumber
  quoteTokenAmount?: BigNumber
  quoteTokenBalance?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  minDepositFeeRate?: BigNumber
  maxDepositFeeRate?: BigNumber
  smoyPerBlock?: BigNumber
  BONUS_MULTIPLIER?: BigNumber
  lpTotalSupply?: BigNumber
  apr?: BigNumber
  userData?: {
    allowance: BigNumber
    tokenBalance: BigNumber
    stakedBalance: BigNumber
    earnings: BigNumber
  }
}

export interface Pool extends PoolConfig {
  totalStaked?: BigNumber
  startBlock?: number
  endBlock?: number
  rewardPerBlock?: number
  userData?: {
    allowance: BigNumber
    stakingTokenBalance: BigNumber
    stakedBalance: BigNumber
    pendingReward: BigNumber
  }
}

// Slices states

export interface FarmsState {
  data: Farm[]
  farmUnlockAt?: Date
}

export interface PoolsState {
  data: Pool[]
}

export interface RaffleCandidate {
  vault: string
  balance: BigNumber
}

export interface RaffleState {
  candidates: RaffleCandidate[]
  raffleBalance: BigNumber
  latestWinner: string
  latestWinnerSymbol: string
  latestWinnerAward: BigNumber
  beginRound?: number
  latestDepositDate?: number
  endRound?: number
  minVaultAmount: BigNumber
  minRaffleAmount: BigNumber
  minVaultCount: BigNumber
  specialPot: string
  specialPotRatio: number
}

export interface VaultsState {
  data: Vault[]
  raffle?: RaffleState
}

export interface Vault extends VaultConfig {
  lpTotalSupply?: BigNumber
  quoteTokenBalanceOfMC?: BigNumber
  quoteTokenBalanceOfStrategy?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  rewardPerBlock?: BigNumber
  lpAmountOfStrategyInMC?: BigNumber
  vaultTotalSupply?: BigNumber
  ratioOfAmountInMCVsVault?: number
  vaultWithdrawFee?: number
  earlyWithdrawFee?: number
  rewardTokenPriceVsQuote?: BigNumber
  pendingReward?: BigNumber
  tvl?: BigNumber
  apr?: BigNumber
  apy?: BigNumber
  userData?: {
    lpAllowance: BigNumber
    lpBalance: BigNumber
    lpStakedBalance: BigNumber
    vaultAllowance: BigNumber
    wbnbAllowance: BigNumber
    wbnbBalance: BigNumber
    busdAllowance: BigNumber
    busdBalance: BigNumber
    usdtAllowance: BigNumber
    usdtBalance: BigNumber
    smoyAllowance: BigNumber
    smoyBalance: BigNumber
    bnbBalance: BigNumber
    depositTime: number
  }
}

export interface SmoyPriceState {
  price: number
  smoyPrice: number
  pancakeBnbPrice: number
  smoyWbnbQuote: number
  smoyBusdQuote: number
  wbnbBusdQuote: number
  usdtBusdQuote: number
  kMaticWbnbQuote: number
}

// Global state

export interface State {
  smoyPrice: SmoyPriceState
  farms: FarmsState
  pools: PoolsState
  vaults: VaultsState
  godfathers: NFTState
  puppys: NFTState
  miniGames: MiniGameState
  lotteries: LotteryState
  marketplace: MarketplaceState
  mintPuppyGame: MintPuppyGame
}

export enum NftTypeEnum {
  Godfather = 'GODFATHER',
  Puppy = 'PUPPY',
}

export enum LotteryRewardTypeEnum {
  GODFATHER = 1,
  PUPPY = 2,
  MOY = 3,
}

export interface NFTInfo {
  tokenId: string
  tokenLabel: string
  ticketType?: number
  pack?: string
  rarity?: string
  imagePath: '' | 'puppy' | 'godfather'
}

export interface NFTState {
  data: NFTInfo[]
  loading: boolean
}

export interface AirDropInfo {
  maxAirDropQuota?: number
  airDropCounter?: number
  freeAirDropCounter?: number
  minimumBalance?: number
}

export interface MiniGameSeat {
  seatNo: number
  account: string
  isWon: boolean
  isClaimed: boolean
}

export interface MiniGameRoom {
  roomNo: number
  seats: MiniGameSeat[]
  awaitingAward: boolean
  winner?: number
}

export interface MiniGameUserData {
  smoyBalance?: string
  smoyAllowance?: string
  busdBalance?: string
  busdAllowance?: string
}

export interface MiniGameState {
  rooms: MiniGameRoom[]
  currentRoomNo: number
  nextWinnerDrawRoomNo: number
  maxSeat: number
  smoyCostPerSeat: string
  busdCostPerSeat: string
  minSeatToImmediatelyBegin: number
  userData: MiniGameUserData
}

export interface LotteryState {
  currentRound: number
  currentAwardRound: number
  latestAwardDateString: string
  latestAwardGodfatherString: string
  latestAwardPuppyString: string
  latestAwardMoyString: string
  godfatherResult: LotteryResultState[]
  puppyResult: LotteryResultState[]
  moyResult: LotteryResultState[]
  moyLottoCheckSmoyBalance: number
  godfatherLottoCheckSmoyBalance: number
}

export interface LotteryResultState {
  round: number
  result: string
  validTokenId: string
  awardTimestamp: number
  isRoundWon: boolean
  isRoundClaimed: boolean
  wonTicket: LotteryWonTicket[]
}

export interface LotteryWonTicket {
  tokenId: string
  lottoPack: number
  lottoNumber: string
  isTicketWon: boolean
  isTicketClaimed: boolean
  isTicketValid: boolean
}

export interface MintPuppyGame {
  busdCost: number
  smoyCost: number
  userData: MiniGameUserData
}

export interface MarketplaceItem extends NFTInfo {
  seller: string
  startingPrice: string
  endingPrice: string
  currentPrice?: number
  duration: number
  startedAt: number
}

export interface MarketHistoryItem extends NFTInfo {
  seller: string
  buyer: string
  price: number
  timestamp: number
}

export interface MarketplaceUserData {
  isApprovedForAll: boolean
  busdBalance?: string
  busdAllowance?: string
}

export interface MarketplaceState {
  totalListing: number
  listings: MarketplaceItem[]
  histories: MarketHistoryItem[]
  paused: boolean
  userData?: MarketplaceUserData
  latestAction?: 'PURCHASED' | 'CANCELLED'
  tokenNotExist?: boolean
}
