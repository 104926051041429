import contracts from './contracts'
import vaultContracts from './vaultContracts'
import { QuoteToken, PlatformConfig } from './types'

export const samoyed: PlatformConfig = {
  platform: 'Samoyed',
  masterchefABI: 'samoyed',
  rewardPerBlockField: 'smoyPerBlock',
  pendingRewardField: 'pendingSmoy',
  masterchefAddresses: contracts.samoyedMasterChef,
  helper: vaultContracts.smoyVaultHelper,
  rewardTokenPriceLPAddresses: contracts.smoyBusdLP,
  tokenAddressOfRewardLP: contracts.smoy,
  tokenSymbolOfRewardLP: QuoteToken.SMOY,
  quoteTokenAddressOfRewardLP: contracts.busd,
  quoteTokenSymbolOfRewardLP: QuoteToken.BUSD,
}

export const biswap: PlatformConfig = {
  platform: 'BiSwap',
  masterchefABI: 'biswap',
  rewardPerBlockField: 'BSWPerBlock',
  pendingRewardField: 'pendingBSW',
  masterchefAddresses: vaultContracts.bswMasterChef,
  helper: vaultContracts.bswVaultHelper,

  rewardTokenPriceLPAddresses: vaultContracts.BSWxBswBusdLP,
  tokenAddressOfRewardLP: vaultContracts.bswToken,
  tokenSymbolOfRewardLP: QuoteToken.BSW,
  quoteTokenAddressOfRewardLP: contracts.busd,
  quoteTokenSymbolOfRewardLP: QuoteToken.BUSD,
}

// export const babyswap: PlatformConfig = {
//   platform: 'BabySwap',
//   masterchefABI: 'babyswap',
//   rewardPerBlockField: 'cakePerBlock',
//   pendingRewardField: 'pendingCake',
//   masterchefAddresses: vaultContracts.babyMasterChef,
//   helper: vaultContracts.babyVaultHelper,

//   rewardTokenPriceLPAddresses: vaultContracts.BABYxBabyBnbLP,
//   tokenAddressOfRewardLP: vaultContracts.babyToken,
//   tokenSymbolOfRewardLP: QuoteToken.BABY,
//   quoteTokenAddressOfRewardLP: contracts.wbnb,
//   quoteTokenSymbolOfRewardLP: QuoteToken.BNB,
// }

export const killswitch: PlatformConfig = {
  platform: 'KillSwitch',
  masterchefABI: 'killswitch',
  rewardPerBlockField: 'kswPerSecond',
  pendingRewardField: 'pendingKSW',
  masterchefAddresses: vaultContracts.kswMasterChef,
  helper: vaultContracts.kswVaultHelper,

  rewardTokenPriceLPAddresses: vaultContracts.KSWxKswBnbLP,
  tokenAddressOfRewardLP: vaultContracts.kswToken,
  tokenSymbolOfRewardLP: QuoteToken.KSW,
  quoteTokenAddressOfRewardLP: contracts.wbnb,
  quoteTokenSymbolOfRewardLP: QuoteToken.BNB,
}
