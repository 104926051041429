import React, { useState } from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import { Close as CloseIcon, FileCopyOutlined as CopyIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { localStorageKey } from './configs'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#E8F4FF',
  },
  address: {
    width: '350px',
    padding: theme.spacing(2),
    backgroundColor: 'rgb(246, 246, 246)',
    borderRadius: theme.spacing(2),
  },
  btnClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnCopy: {
    padding: '0px',
    float: 'right',
    color: '#ffb8c4',
  },
  row: {
    textAlign: 'center',
  },
  bscscan: {
    color: '#ffb8c4',
  },
  btnDisconnect: {
    width: '100%',
    color: '#ffb8c4',
    border: '2px solid #ffb8c4',
    borderRadius: '10px',
    marginTop: theme.spacing(2),
  },
  btnAccount: {
    width: '170px',
  },
}))

const UserModal = ({ open, onClose }) => {
  const classes = useStyles()
  const { account, reset } = useWallet()
  const [openTooltip, setOpenTooltip] = useState(false)
  const accountEllipsis = account ? `${account.substring(0, 8)}...${account.substring(account.length - 8)}` : null

  const handleCopy = () => {
    navigator.clipboard.writeText(account)
    handleTooltipOpen()
  }

  const handleDisconnect = () => {
    onClose()
    reset()
    window.localStorage.removeItem(localStorageKey)
    window.location.reload()
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} className={classes.root}>
      <DialogTitle className={classes.title}>
        <Typography variant="h4">Your Wallet</Typography>
        <IconButton aria-label="close" className={classes.btnClose} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="caption">Your Address</Typography>
        <Box component="div" m={1} className={classes.address}>
          {accountEllipsis}

          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              open={openTooltip}
              title="Copied"
              onClose={handleTooltipClose}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <IconButton aria-label="copy" className={classes.btnCopy} onClick={handleCopy} size="large">
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </Box>
        <Box component="p" m={1}>
          <Link href={`https://bscscan.com/address/${account}`} target="_blank" className={classes.bscscan}>
            View on BscScan
          </Link>
        </Box>
        <Box component="p" m={1} className={classes.row}>
          <Button className={classes.btnDisconnect} onClick={handleDisconnect}>
            Disconnect Wallet
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UserModal
