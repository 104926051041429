export default {
  // MasterChef =====================================================
  pancakeMasterChef: {
    97: '0xecABdE9d04506663c749D9B432b422Cb1dA3A3cB',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  samoyedMasterChef: {
    97: '0xecABdE9d04506663c749D9B432b422Cb1dA3A3cB',
    56: '0x5D21D02378670119453530478288AEe67b807e2a',
  },

  // Token =====================================================
  wbnb: {
    97: '0xCAba8F3ed2493CAC6AbEb493b4F10419d9c37e06',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  busd: {
    97: '0xaB3F4ebABFd6898452543454AAE908Df71658801',
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  usdt: {
    97: '0x26F7A9eED4C7d496926b8FD90Bf51accF3CF8d92',
    56: '0x55d398326f99059fF775485246999027B3197955',
  },
  smoy: {
    97: '0xb64E0710e59ea2A08E65EB2496973F27e818664c',
    56: '0xBdb44DF0A914c290DFD84c1eaf5899d285717fdc',
  },
  kMatic: {
    97: '0x582457CE6543597Eb5D913c1CD47d96ba8CFa4C9',
    56: '0x032574B64Bf6fa42951f836CC8c5099d1C5747d3',
  },
  coupon: {
    97: '0x53705AFc23AAA8Ea3538D552CEA33d791251238F',
    56: '0x084bb94e93891D74579B54Ab63ED24C4ef9cd5Ef',
  },

  // LP =====================================================
  smoyBnbLP: {
    97: '0x50d787Bb18dfFd6FA9fC95f62DF827FBE959fd06',
    56: '0xf8c3cedc71380BCB0b7103a270060c0041098bA2',
  },
  smoyBusdLP: {
    97: '0x2C67AF1f0CBDc2b6116149060Ca1Abf9AC7A0b24',
    56: '0x0c48e28Ce5D06D13DD790D7568b06498aEEC48b5',
  },
  bnbBusdLP: {
    97: '0x32C14bb8d97Cd18eA3Db715BB4e075b89C3317B3',
    56: '0xd241257d8fC4AC288fDEDA03dFF18aF373d968c8',
  },
  usdtBusdLP: {
    97: '0xfFC5E7c2067e34E396aCD51415AFB60c715C2Bbe',
    56: '0x2e3FDF8474F02D75933cA001BC5A70f6707DdB80',
  },
  kMaticBnbLP: {
    97: '0x365FAB678B57064eFa4F39D0e70BbFF4d6846A8C',
    56: '0xf51946D79BC438dDA6DC3E5B138C09c1949b38CC',
  },
  pancakeBnbBusdLP: {
    97: '0x32C14bb8d97Cd18eA3Db715BB4e075b89C3317B3',
    56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
  },

  // other =====================================================
  mulltiCall: {
    97: '0x67c227b091C664749156a51Fb9FC7e338C0038c9',
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
  },
  godfatherStorage: {
    97: '0x1C7991cEc96383Dc49D70DB23e6DdBe04b60E614',
    56: '0x1ff7F3FeFc10DdF3D827bd8Df109a0885f542114',
  },
  puppyStorage: {
    97: '0xa25644d845cdc4D2e05420C787eeDf0F38Edc60f',
    56: '0x6515CF06686F8Eed508b880C8e5E8e9Cf9c6b686',
  },
  miniGame: {
    97: '0xF1d7d77d854443e32788D76e8c5dE6bD4F1Bfb33',
    56: '0x8bBcb00244DaA5DE96E2e11f9b234d85234A3570',
  },
  airDropUtil: {
    97: '0x0DC0E3bF8F177b132C87BB68Ed6ae5Fa033f6F6C',
    56: '0xA93dd038A8f1E51a678E546b72A9F1E2BE9c6b2e',
  },
  marketplace: {
    97: '0xdE65CF6Ef7bC704d0254D42D10A9E3E062B708ab',
    56: '0x4Cf94f38C67d60725756D6E76Fb046c07De41594',
  },
  lottoAward: {
    97: '0x1B22cBf0f40D18DD5Ddda93B5Cf5fa9182b87133',
    56: '0xcA8316F147B2fC0BB362984884EaDdbf0cDccae1',
  },
  lottoCheckGodfather: {
    97: '0xf818Af518B1337D42dB8eE879D95f4998abC1c1A',
    56: '0xbA207E6aAB7ee6bfd995723A068aF5AF542efd63',
  },
  lottoCheckPuppy: {
    97: '0x9927B1A90573825369c4cBC58A74971787B09660',
    56: '0x04dC8C8D35A521451bdcEE53786ca603BF133FA4',
  },
  lottoCheckMoy: {
    97: '0x9efc5fE607e342239c6ffDd1F25341EEC8cF84A6',
    56: '0x1e9716eE9B5EF89bA1FcA2a34b701316643E8096',
  },
  mintPuppyGame: {
    97: '0x7C8e4a5A5E47936Fa1aE7Ea5F46582E8A21be14A',
    56: '0x66719104336D6be7d843FeB7Eda6C86248E64Fb8',
  },
}
