import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import marketplaceABI from 'config/abi/marketplace.json'
import puppyStorageABI from 'config/abi/puppyStorage.json'
import erc20ABI from 'config/abi/erc20.json'
import { MarketplaceState, MarketplaceItem, MarketplaceUserData, MarketHistoryItem } from 'state/types'
import { getMarketplaceAddress, getBusdAddress, getPuppyStorageAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { toNumberHex } from 'utils/nftUtils'

const initialState: MarketplaceState = {
  totalListing: 0,
  paused: false,
  listings: [],
  histories: [],
  userData: null,
}

interface _PublicInfo {
  entryCount: number
  paused: boolean
  listings: MarketplaceItem[]
}

const PAGE_SIZE = 30
const HISTORY_SIZE = 30

const compareListing = (existItems: MarketplaceItem[], newItems: MarketplaceItem[]) => {
  return newItems.every((a) => {
    const b = existItems.find((i) => i.tokenId === a.tokenId)
    if (b) {
      return (
        a.seller === b.seller &&
        a.startingPrice === b.startingPrice &&
        a.endingPrice === b.endingPrice &&
        a.duration === b.duration
      )
    }
    return false
  })
}

export const marketplaceSlice = createSlice({
  name: 'MarketplaceReducer',
  initialState,
  reducers: {
    setMarketplacePublicInfo: (state, action) => {
      const { entryCount, paused, listings }: _PublicInfo = action.payload

      // Prevent unnecessary re-render
      if (!compareListing(state.listings, listings)) {
        const newTokenIds = listings.map((i) => {
          return i.tokenId
        })

        const _stateListings = state.listings.filter((i) => !newTokenIds.includes(i.tokenId))

        listings.forEach((item) => {
          const _item = item
          _item.tokenLabel = toNumberHex(Number(item.tokenId), 2)
          _item.imagePath = 'puppy'

          _stateListings.push({ ..._item })
        })

        return { ...state, totalListing: entryCount, paused, listings: [..._stateListings] }
      }
      return state
    },

    setMarketplaceSingleListing: (state, action) => {
      const item: MarketplaceItem = action.payload
      if (!compareListing(state.listings, [item])) {
        item.tokenLabel = toNumberHex(Number(item.tokenId), 2)
        item.imagePath = 'puppy'
        let _listings = state.listings.filter((i) => i.tokenId !== item.tokenId)
        _listings.push(item)
        _listings = _.sortBy(_listings, ['tokenId'])
        return { ...state, listings: [..._listings], tokenNotExist: false }
      }
      if (state.tokenNotExist !== false) {
        return { ...state, tokenNotExist: false }
      }
      return state
    },

    hideListingFromState: (state, action) => {
      const tokenId = action.payload
      const _listings = state.listings.filter((i) => i.tokenId !== tokenId)
      return { ...state, listings: [..._listings] }
    },

    setMarketplaceHistory: (state, action) => {
      const items: MarketHistoryItem[] = action.payload
      const keys = items.map((i) => {
        return i.tokenId
      })

      const existIds = state.histories.map((i) => {
        return i.tokenId
      })

      // Prevent unnecessary re-render
      if (
        !keys.every((id) => {
          return existIds.includes(id)
        })
      ) {
        const _items = state.histories.filter((i) => !keys.includes(i.tokenId))
        items.forEach((item) => {
          const _item = item
          _item.tokenLabel = toNumberHex(Number(item.tokenId), 2)
          _item.imagePath = 'puppy'
          _items.push(_item)
        })
        return { ...state, histories: [..._items] }
      }
      return state
    },

    setMarketplaceUserData: (state, action) => {
      const data: MarketplaceUserData = action.payload
      return { ...state, userData: { ...data } }
    },

    setLatestActionState: (state, action) => {
      return { ...state, latestAction: action.payload }
    },

    setTokenNotExist: (state, action) => {
      return { ...state, tokenNotExist: action.payload }
    },
  },
})

// Actions
export const {
  setMarketplacePublicInfo,
  setMarketplaceHistory,
  setMarketplaceUserData,
  setMarketplaceSingleListing,
  hideListingFromState,
  setLatestActionState,
  setTokenNotExist,
} = marketplaceSlice.actions

/**
 * Frequent fetch first 30 listings
 */
export const fetchMarketplacePublicInfo = () => async (dispatch) => {
  const marketplaceAddress = getMarketplaceAddress()

  const [_entryCount, _paused] = await multicall(marketplaceABI, [
    { address: marketplaceAddress, name: 'entryCount', params: [] },
    { address: marketplaceAddress, name: 'paused', params: [] },
  ])
  const entryCount = new BigNumber(_entryCount[0]._hex).toNumber()
  const paused = _paused[0]

  const from = entryCount - PAGE_SIZE >= 0 ? entryCount - PAGE_SIZE : 0

  const [_listings] = await multicall(marketplaceABI, [
    { address: marketplaceAddress, name: 'getEntriesByOffset', params: [from, PAGE_SIZE] },
  ])
  const listings = _listings[0].map((i) => {
    return {
      tokenId: i.tokenId.toString(),
      seller: i.seller,
      startingPrice: i.startingPrice.toString(),
      endingPrice: i.endingPrice.toString(),
      startedAt: i.startedAt.toNumber(),
      duration: i.duration.toNumber(),
    }
  })
  dispatch(setMarketplacePublicInfo({ entryCount, paused, listings }))
}

/**
 * Frequent fetch the rest of first 30 listings infrequently
 */
export const fetchAllMarketplaceListings = () => async (dispatch) => {
  const marketplaceAddress = getMarketplaceAddress()

  const [_entryCount] = await multicall(marketplaceABI, [
    { address: marketplaceAddress, name: 'entryCount', params: [] },
  ])
  const entryCount = new BigNumber(_entryCount[0]._hex).toNumber()

  const fetchList = getFetchPaging(entryCount, PAGE_SIZE)

  fetchList.shift()

  await Promise.all(
    fetchList.map(async (fetch) => {
      const [_listings] = await multicall(marketplaceABI, [
        { address: marketplaceAddress, name: 'getEntriesByOffset', params: [fetch.from, fetch.limit] },
      ])
      const listings = _listings[0].map((i) => {
        return {
          tokenId: i.tokenId.toString(),
          seller: i.seller,
          startingPrice: i.startingPrice.toString(),
          endingPrice: i.endingPrice.toString(),
          startedAt: i.startedAt.toNumber(),
          duration: i.duration.toNumber(),
        }
      })
      dispatch(setMarketplacePublicInfo({ entryCount, listings }))
    })
  )
}

export const fetchHistory = (latestIndex: number) => async (dispatch) => {
  const marketplaceAddress = getMarketplaceAddress()
  const [_purchaseCount] = await multicall(marketplaceABI, [
    { address: marketplaceAddress, name: 'purchaseCount', params: [] },
  ])

  const purchaseCount = new BigNumber(_purchaseCount[0]._hex).toNumber()
  if (purchaseCount === 0) {
    dispatch(setMarketplaceHistory([]))
    return
  }

  if (latestIndex < purchaseCount) {
    const fetchList = getFetchPaging(purchaseCount, HISTORY_SIZE, latestIndex)

    await Promise.all(
      fetchList.map(async (fetch) => {
        const calls = []
        for (let i = fetch.from; i <= fetch.to; i++) {
          calls.push({ address: marketplaceAddress, name: 'purchaseLogs', params: [i] })
        }

        const _purchaseLogs = await multicall(marketplaceABI, calls.reverse())
        const purchaseLogs = _purchaseLogs.map((i) => {
          return {
            tokenLabel: toNumberHex(Number(i.tokenId.toString()), 2),
            tokenId: i.tokenId.toString(),
            buyer: i.buyer,
            seller: i.seller,
            price: i.price.toString(),
            timestamp: i.timestamp.toNumber(),
          }
        })

        dispatch(setMarketplaceHistory(purchaseLogs))
      })
    )

    const start = purchaseCount > HISTORY_SIZE ? purchaseCount - HISTORY_SIZE : 0
    const calls = []
    for (let i = start; i < purchaseCount; i++) {
      calls.push({ address: marketplaceAddress, name: 'purchaseLogs', params: [i] })
    }

    const _purchaseLogs = await multicall(marketplaceABI, calls.reverse())
    const purchaseLogs = _purchaseLogs.map((i) => {
      return {
        tokenLabel: toNumberHex(Number(i.tokenId.toString()), 2),
        tokenId: i.tokenId.toString(),
        buyer: i.buyer,
        seller: i.seller,
        price: i.price.toString(),
        timestamp: i.timestamp.toNumber(),
      }
    })

    dispatch(setMarketplaceHistory(purchaseLogs))
  }
}

/**
 * Fetch single listing information
 * @tokenId {string} ERC721 TokenId
 */
export const fetchListingInfo = (tokenId: string) => async (dispatch) => {
  const marketplaceAddress = getMarketplaceAddress()
  try {
    const [_listing] = await multicall(marketplaceABI, [
      { address: marketplaceAddress, name: 'getListing', params: [tokenId] },
    ])
    const listing = {
      tokenId,
      seller: _listing.seller,
      startingPrice: _listing.startingPrice.toString(),
      endingPrice: _listing.endingPrice.toString(),
      startedAt: _listing.startedAt.toNumber(),
      duration: _listing.duration.toNumber(),
    }

    dispatch(setMarketplaceSingleListing(listing))
  } catch (err) {
    console.error(err)
    dispatch(setTokenNotExist(true))
  }
}

/**
 * Hide single listing after purchased or cancelled
 * @tokenId {string} ERC721 TokenId
 */
export const hideListing = (tokenId: string) => async (dispatch) => {
  dispatch(hideListingFromState(tokenId))
}

/**
 * Set Latest Action of current user
 * @action {string} PURCHASED or CANCELLED
 */
export const setLatestAction = (action: 'PURCHASED' | 'CANCELLED') => async (dispatch) => {
  dispatch(setLatestActionState(action))
}

/**
 * Fetch user's balance and approval
 * @account {string} user's address
 */
export const fetchUserData = (account: string) => async (dispatch) => {
  if (!account) return

  const marketplaceAddress = getMarketplaceAddress()
  const puppyStorageAddress = getPuppyStorageAddress()
  const busdAddress = getBusdAddress()

  const [_isApprovedForAll] = await multicall(puppyStorageABI, [
    { address: puppyStorageAddress, name: 'isApprovedForAll', params: [account, marketplaceAddress] },
  ])

  const calls = [
    { address: busdAddress, name: 'balanceOf', params: [account] },
    { address: busdAddress, name: 'allowance', params: [account, marketplaceAddress] },
  ]
  const [_balanceOf, _allowance] = await multicall(erc20ABI, calls)

  dispatch(
    setMarketplaceUserData({
      isApprovedForAll: _isApprovedForAll[0] === true,
      busdBalance: new BigNumber(_balanceOf[0]._hex).toJSON(),
      busdAllowance: new BigNumber(_allowance[0]._hex).toJSON(),
    })
  )
}

const getFetchPaging = (total: number, pageSize: number, startIndex = 0) => {
  let index = total - 1
  const fetchList = []
  while (index >= startIndex) {
    const to = index
    const from = index - pageSize + 1 >= startIndex ? index - pageSize + 1 : startIndex
    let limit = pageSize > to ? to + 1 : pageSize
    limit = from === to ? 1 : limit
    fetchList.push({ from, to, limit })
    index -= pageSize
  }
  return fetchList
}

export default marketplaceSlice.reducer
