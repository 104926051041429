import { sortBy } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import miniGameABI from 'config/abi/miniGame.json'
import erc20ABI from 'config/abi/erc20.json'
import { getMiniGameAddress, getSmoyAddress, getBusdAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { MiniGameState, MiniGameUserData, MiniGameRoom, MiniGameSeat } from 'state/types'

const initialState: MiniGameState = {
  rooms: [],
  currentRoomNo: 0,
  nextWinnerDrawRoomNo: 0,
  maxSeat: 16,
  smoyCostPerSeat: '0',
  busdCostPerSeat: '0',
  minSeatToImmediatelyBegin: 4,
  userData: {},
}

export const miniGameSlice = createSlice({
  name: 'MiniGameRecucer',
  initialState,
  reducers: {
    setMiniGamePublicInfo: (state, action) => {
      const data: MiniGameState = action.payload
      const _rooms = [...data.rooms]
      state.rooms.forEach((room) => {
        const existRoom = _rooms.find((i) => i.roomNo === room.roomNo)
        if (!existRoom) {
          _rooms.push(room)
        }
      })
      data.rooms = sortBy(_rooms, ['roomNo']).reverse()
      return { ...state, ...data }
    },

    setMiniGamePreviousRoom: (state, action) => {
      const data: MiniGameRoom = action.payload
      let _rooms = state.rooms.filter((i) => i.roomNo !== data.roomNo)
      _rooms.push(data)
      _rooms = sortBy(_rooms, ['roomNo']).reverse()
      return { ...state, rooms: [..._rooms] }
    },

    setMiniGameUserData: (state, action) => {
      const data: MiniGameUserData = action.payload
      return { ...state, userData: { ...data } }
    },
  },
})

// Actions
export const { setMiniGamePublicInfo, setMiniGamePreviousRoom, setMiniGameUserData } = miniGameSlice.actions

export const fetchMiniGamePublicInfo = () => async (dispatch) => {
  const miniGameAddress = getMiniGameAddress()

  const calls = [
    { address: miniGameAddress, name: 'currentRoomNo', params: [] },
    { address: miniGameAddress, name: 'nextWinnerDrawRoomNo', params: [] },
    { address: miniGameAddress, name: 'maxSeat', params: [] },
    { address: miniGameAddress, name: 'smoyCostPerSeat', params: [] },
    { address: miniGameAddress, name: 'busdCostPerSeat', params: [] },
    { address: miniGameAddress, name: 'minimumSeatToImmediatelyBeginRound', params: [] },
  ]
  const [
    _currentRoomNo,
    _nextWinnerDrawRoomNo,
    _maxSeat,
    _smoyCostPerSeat,
    _busdCostPerSeat,
    _minSeatToImmediatelyBegin,
  ] = await multicall(miniGameABI, calls)

  const currentRoomNo = new BigNumber(_currentRoomNo[0]._hex).toNumber()
  const nextWinnerDrawRoomNo = new BigNumber(_nextWinnerDrawRoomNo[0]._hex).toNumber()
  const maxSeat = _maxSeat[0]
  const minSeatToImmediatelyBegin = new BigNumber(_minSeatToImmediatelyBegin[0]._hex).toNumber()
  const callsSeatAddress = Array.from({ length: maxSeat }, (v, k) => k).map((i) => {
    return { address: miniGameAddress, name: 'sitAddress', params: [currentRoomNo, i] }
  })

  const _seatAddresses = await multicall(miniGameABI, callsSeatAddress)
  const seatAddresses = _seatAddresses.map((addr, index) => {
    return { seatNo: index, account: addr.toString() }
  })

  dispatch(
    setMiniGamePublicInfo({
      rooms: [{ roomNo: currentRoomNo, seats: seatAddresses }],
      currentRoomNo,
      nextWinnerDrawRoomNo,
      maxSeat,
      smoyCostPerSeat: new BigNumber(_smoyCostPerSeat[0]._hex).toJSON(),
      busdCostPerSeat: new BigNumber(_busdCostPerSeat[0]._hex).toJSON(),
      minSeatToImmediatelyBegin,
    })
  )
}

export const fetchMiniGamePreviousRoom = (roomNo: number, account?) => async (dispatch) => {
  if (roomNo < 0) return

  const miniGameAddress = getMiniGameAddress()
  const [_maxSeat, _nextWinnerDrawRoomNo, _winner] = await multicall(miniGameABI, [
    { address: miniGameAddress, name: 'maxSeat', params: [] },
    { address: miniGameAddress, name: 'nextWinnerDrawRoomNo', params: [] },
    { address: miniGameAddress, name: 'winner', params: [roomNo] },
  ])
  const maxSeat = _maxSeat[0]
  const nextWinnerDrawRoomNo = new BigNumber(_nextWinnerDrawRoomNo[0]._hex).toNumber()

  const awaitingWinner = roomNo >= nextWinnerDrawRoomNo
  const winner = awaitingWinner ? undefined : _winner[0]

  const calls = []
  Array.from({ length: maxSeat }, (v, k) => k).forEach((i) => {
    calls.push({ address: miniGameAddress, name: 'sitAddress', params: [roomNo, i] })
  })
  const _seatAddresses = await multicall(miniGameABI, calls)

  const seatAddresses = _seatAddresses.map((addr, index) => {
    return {
      seatNo: index,
      account: addr.toString(),
      awaitingWinner,
      isWon: !awaitingWinner && winner === index,
      isClaimed: undefined,
    }
  })

  if (account && seatAddresses.some((i) => i.account === account)) {
    const callsClaimed = [{ address: miniGameAddress, name: 'isAddressClaimedPrize', params: [roomNo, account] }]
    const [_claimed] = await multicall(miniGameABI, callsClaimed)
    const _seats: [] = seatAddresses.filter((i) => i.account === account)
    _seats.forEach((seat: MiniGameSeat) => {
      const tmp = seat
      tmp.isClaimed = _claimed[0]
    })
  }

  dispatch(setMiniGamePreviousRoom({ roomNo, seats: seatAddresses, winner }))
}

export const fetchUserData = (account) => async (dispatch) => {
  if (!account) return

  const miniGameAddress = getMiniGameAddress()
  const smoyAddress = getSmoyAddress()
  const busdAddress = getBusdAddress()
  const calls = [
    { address: smoyAddress, name: 'balanceOf', params: [account] },
    { address: smoyAddress, name: 'allowance', params: [account, miniGameAddress] },
    { address: busdAddress, name: 'balanceOf', params: [account] },
    { address: busdAddress, name: 'allowance', params: [account, miniGameAddress] },
  ]

  const [_smoyBalanceOf, _smoyAllowance, _busdBalanceOf, _busdAllowance] = await multicall(erc20ABI, calls)
  dispatch(
    setMiniGameUserData({
      smoyBalance: new BigNumber(_smoyBalanceOf[0]._hex).toJSON(),
      smoyAllowance: new BigNumber(_smoyAllowance[0]._hex).toJSON(),
      busdBalance: new BigNumber(_busdBalanceOf[0]._hex).toJSON(),
      busdAllowance: new BigNumber(_busdAllowance[0]._hex).toJSON(),
    })
  )
}

export const fetchCurrentRoomNo = async () => {
  const [_currentRoomNo] = await multicall(miniGameABI, [
    { address: getMiniGameAddress(), name: 'currentRoomNo', params: [] },
  ])
  return new BigNumber(_currentRoomNo[0]._hex).toNumber()
}

export default miniGameSlice.reducer
